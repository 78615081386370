
button[data-v-330130b3]:hover {
  color: #fff;
}
.study-detail[data-v-330130b3] {
    width: 1200px;
    margin: 0 auto;
}
.study-detail .ant-breadcrumb[data-v-330130b3]{
    text-align: left;
    margin: 10px 0;
    width: 200px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.study-detail .classesWrap[data-v-330130b3] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 30px;
}
.study-detail .img[data-v-330130b3] {
    width: 560px;
    height: 315px;
    margin-right: 30px;
    border-radius: 4px;
    overflow: hidden;
}
.study-detail .img img[data-v-330130b3] {
    -o-object-fit: cover;
       object-fit: cover;
}
.study-detail .info[data-v-330130b3] {
    height: 315px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.study-detail .title[data-v-330130b3] {
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    min-height: 24px;
}
.study-detail .txt[data-v-330130b3] {
    text-align: left;
    margin-bottom: 30px;
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    min-height: 80px;
    line-height: 20px;
}
.price-info[data-v-330130b3] {
  height: 52px;
  background: #E1E8EB;
  line-height: 52px;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  padding: 0 10px;
  color: #666;
  border-radius: 4px;
}
.red[data-v-330130b3] {
  color: #FC583D;
  font-weight: bold;
  margin-left: 10px;
}
@media screen and (max-width: 1024px) {
.study-detail[data-v-330130b3] {
        width: 100%;
}
.study-detail .classesWrap[data-v-330130b3] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
}
.study-detail .img[data-v-330130b3] {
        width: 100%;
        height: 240px;
        margin-right: 0;
        padding-top: 20px;
}
.study-detail .info[data-v-330130b3] {
        height: auto;
}
}
@media screen and (min-width:768px) and (max-width:1024px){
.study-detail .img[data-v-330130b3] {
        height: 480px;
}
}
