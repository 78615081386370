
.content[data-v-7067a251] {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background: url(../img/homeBg.f35eb24b.png) no-repeat;
    background-position: 0 380px;
}
.ant-carousel[data-v-7067a251] .slick-slide {
    text-align: center;
    /* height: 55vh;
    line-height: 55vh; */
    background: #fff;
    overflow: hidden;
}
.ant-carousel[data-v-7067a251] .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
}
.ant-carousel[data-v-7067a251] .custom-slick-arrow:before {
    display: none;
}
.ant-carousel[data-v-7067a251] .custom-slick-arrow:hover {
    opacity: 0.5;
}
.ant-carousel[data-v-7067a251] .slick-slide h3 {
    color: #fff;
}
.carouselImgWrap img[data-v-7067a251] {
    -o-object-fit: cover;
       object-fit: cover;
    max-height: 625px;
}
.title[data-v-7067a251] {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
}
.title[data-v-7067a251]::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: #11A0F8;
}
.product-list[data-v-7067a251] {
    width: 790px;
    padding-bottom: 30px;
}
.product-list .product-content[data-v-7067a251] {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
}
.titleWrap[data-v-7067a251]{
    width: 100%;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}
.product-list .imgWrap[data-v-7067a251] {
    height: 170px;
    overflow: hidden;
    position: relative;
}
.product-list .imgWrap img[data-v-7067a251]{
    -o-object-fit: cover;
       object-fit: cover;
}
.product-list .product-content li[data-v-7067a251] {
    width: 245px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 27px;
}
.product-list .product-content li:hover .hover-item[data-v-7067a251] {
    /* transform: scale(1.1); */
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.hover-item[data-v-7067a251] {
    -webkit-transition: all .5s;
    transition: all .5s;
    border-radius: 6px;
    -webkit-box-shadow: 5px 5px 30px 0px rgba(30, 126, 205, 0.08);
            box-shadow: 5px 5px 30px 0px rgba(30, 126, 205, 0.08);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    height: 256px;
    position: relative;
}
.front[data-v-7067a251] {
    z-index: 222222;
}
.back[data-v-7067a251] {
    z-index: 1111111;
    height: 256px;
    background: #0071F5;
    width: 256px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    padding: 40px 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.back button[data-v-7067a251] {
    width: 86px;
    height: 28px;
    font-size: 14px;
    color: #0071F5;
}
.back-title[data-v-7067a251] {
    font-size: 20px;
    text-align: left;
    line-height: 20px;
    height: 20px;
    color: #fff;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.back-info[data-v-7067a251] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
    text-align: left;
}
.front[data-v-7067a251], .back[data-v-7067a251] {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 6px;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.msgWrap[data-v-7067a251] {
    background: #fff;
    height: 86px;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.msgWrap .price-item[data-v-7067a251] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.price-item .price-sign[data-v-7067a251] {
    color: #aaa;
    margin-right: 5px;
}
.price-item .price[data-v-7067a251] {
    height: 18px;
    line-height: 18px;
    color:#D70000;
    font-size:18px;
}
.product-list .product-content li[data-v-7067a251]:nth-child(3n) {
    margin-right: 0;
}
.product-list .msgTitle[data-v-7067a251] {
    font-size: 18px;
    min-height: 20px;
    line-height: 20px;
    height: 20px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-weight: bold;
    margin-bottom: 0;
}
.product-list .classTime[data-v-7067a251] {
    margin-bottom: 0;
    color: #aaa;
    line-height: 12px;
    text-align: left;
    padding-left: 10px;
}
.icons[data-v-7067a251] {
    background: rgb(255, 134, 17);
    color: #fff!important;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 8px;
    border-radius: 4px;
}
.flowWrap[data-v-7067a251] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
    padding: 30px 0;
}
.flowWrap .icon[data-v-7067a251] {
    width: 36px;
    height: 36px;
    margin-bottom: 10px;
}
.flowWrap .item[data-v-7067a251] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all .3s;
    transition: all .3s;
}
@-webkit-keyframes run-7067a251 {
10%{-webkit-transform:rotate(0deg);}
20%{-webkit-transform:rotate(10deg);}
30%{-webkit-transform:rotate(0deg);}
40%{-webkit-transform:rotate(-10deg);}
50%{-webkit-transform:rotate(0deg);}
60%{-webkit-transform:rotate(10deg);}
70%{-webkit-transform:rotate(0deg);}
80%{-webkit-transform:rotate(-10deg);}
90%{-webkit-transform:rotate(0deg);}
}
@keyframes run-7067a251 {
10%{-webkit-transform:rotate(0deg);}
20%{-webkit-transform:rotate(10deg);}
30%{-webkit-transform:rotate(0deg);}
40%{-webkit-transform:rotate(-10deg);}
50%{-webkit-transform:rotate(0deg);}
60%{-webkit-transform:rotate(10deg);}
70%{-webkit-transform:rotate(0deg);}
80%{-webkit-transform:rotate(-10deg);}
90%{-webkit-transform:rotate(0deg);}
}
.flowWrap .item[data-v-7067a251]:hover {
    -webkit-animation:run-7067a251 .8s linear;
            animation:run-7067a251 .8s linear;
}
.flowWrap .in[data-v-7067a251] {
    width: 120px;
    height: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 60px;
    background: #F5F7F9;
}
.flowWrap .line[data-v-7067a251] {
    width: 50px;
    border-bottom: 2px dashed #ccc;
    margin: 0 10px;
    height: 0;
    list-style: none;
}
.search-module[data-v-7067a251] {
    width: 376px;
    background: #fff;
    height:256px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-right: 10px;
    padding: 40px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
}
.search-module h3[data-v-7067a251] {
    text-align: left;
}
.search-wrap[data-v-7067a251] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.search-item[data-v-7067a251] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
.search-item label[data-v-7067a251] {
    min-width: 70px;
    text-align: left;
}
.search-item input[data-v-7067a251] {
    width: 100%;
    height: 40px;
}
.search-module .search-btn[data-v-7067a251] {
    height: 40px;
    width: 190px;
    border-radius: 20px;
}
.pr[data-v-7067a251] {
    position: relative;
}
.class-icon[data-v-7067a251] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999999999;
    background: #0071F5;
    color: #fff;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 4px 20px;
    font-size: 12px;
}
@media screen and (max-width: 1024px) {
.content[data-v-7067a251] {
      width: 100%;
}
.ant-carousel[data-v-7067a251] .slick-slide {
      height: 210px;
      line-height: 210px;
}
.carouselImgWrap[data-v-7067a251] {
      height: 210px;
}
.product-list[data-v-7067a251] {
      width: 100%;
}
.product-list .product-content[data-v-7067a251] {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.product-list .product-content li[data-v-7067a251] {
      width: 100%;
      margin-right: 0;
}
.flowWrap .line[data-v-7067a251] {
      display: none;
}
.flowWrap .in[data-v-7067a251] {
      width: 68px;
      height: 68px;
}
.flowWrap .in .txt[data-v-7067a251] {
      font-size: 12px;
}
.flowWrap .icon[data-v-7067a251] {
      width: 24px;
      height: 24px;
      margin-bottom: 3px;
}
.flowWrap[data-v-7067a251] {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 25px 0;
}
}
@media screen and (min-width:768px) and (max-width:1024px){
.product-list .imgWrap[data-v-7067a251] {
    height: 420px;
}
}
.listModule[data-v-7067a251] {
  width: 100%;
}
.listModule .titleWrap[data-v-7067a251] {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}
.listModule .titleWrap h3[data-v-7067a251] {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #000;
  overflow: hidden;
}
.listModule .starWrap[data-v-7067a251] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.listModule .starTxt[data-v-7067a251] {
  height: 14px;
  line-height: 14px;
  color: #888;
}
.listModule .titles[data-v-7067a251] {
  background: url(../img/titleBg.ce523056.png) no-repeat;
  min-width: 160px!important;
  margin: 0 auto;
  font-size: 24px!important;
  line-height: 50px;
  height: 50px;
  background-size: 160px auto;
  background-position-y: 100%;
  background-position-x: center;
}
.listModule .doWrap[data-v-7067a251] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.listModule .doWrap p[data-v-7067a251] {
  margin-bottom: 0;
}
.listModule ul[data-v-7067a251] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 30px;
}
.listModule .title[data-v-7067a251] {
  font-size: 18px;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.listModule ul li[data-v-7067a251] {
  background: #fff;
  cursor: pointer;
  width: 360px;
  -webkit-box-shadow: 5px 5px 30px 0px rgba(30, 126, 205, 0.08);
          box-shadow: 5px 5px 30px 0px rgba(30, 126, 205, 0.08);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 60px;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.listModule ul li[data-v-7067a251]:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.listModule ul li[data-v-7067a251]:nth-child(3n) {
  margin-right: 0;
}
.listModule .imgWrap[data-v-7067a251] {
  height: 180px;
  margin-bottom: 10px;
  position: relative;
}
.listModule .imgWrap img[data-v-7067a251] {
  -o-object-fit: cover;
     object-fit: cover;
}
.listModule .more[data-v-7067a251] {
  cursor: pointer;
}
.listModule .icon[data-v-7067a251] {
    background: rgb(255, 134, 17);
    color: #fff!important;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 8px;
    border-radius: 4px;
}
.listModule .price-sign[data-v-7067a251] {
    color: #aaa;
    margin-right: 5px;
}
.listModule .price[data-v-7067a251] {
  height: 18px;
  line-height: 18px;
  color:#D70000;
  font-size:18px;
}
@media screen and (max-width: 1024px) {
.listModule ul li[data-v-7067a251] {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
}
.listModule .imgWrap[data-v-7067a251] {
    height: 200px;
}
}
@media screen and (min-width:768px) and (max-width:1024px){
.listModule .imgWrap[data-v-7067a251] {
    height: 480px;
}
}
