
.class-wrap[data-v-97d4705e] {
     padding-bottom: 20px;
}
.info[data-v-97d4705e] {
     height: 300px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: center;
         -ms-flex-pack: center;
             justify-content: center;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     padding: 20px 0;
     margin-bottom: 30px;
}
.inner[data-v-97d4705e] {
     width: 1200px;
     margin: 0 auto;
}
.info-txt[data-v-97d4705e] {
     -webkit-box-flex: 1;
         -ms-flex: 1;
             flex: 1;
     height: 100%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: justify;
         -ms-flex-pack: justify;
             justify-content: space-between;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
         -ms-flex-direction: column;
             flex-direction: column;
}
.info-txt .title[data-v-97d4705e] {
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     margin-bottom: 12px;
     line-height: 28px;
}
.info-txt .about[data-v-97d4705e] {
     color: #666;
     font-size: 14px;
     text-align: left;
     margin-bottom: 15px;
     min-height: 44px;
     line-height: 22px;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
     overflow: hidden;
}
.info-txt .class-time[data-v-97d4705e] {
     text-align: left;
     color: #666;
     font-size: 12px;
     margin-bottom: 10px;
}
.info-txt .price-wrap[data-v-97d4705e] {
     min-height: 50px;
     background: #E1E8EB;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: start;
         -ms-flex-pack: start;
             justify-content: flex-start;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     padding: 0 10px;
     margin-bottom: 10px;
}
.info-txt .price-wrap span[data-v-97d4705e]:first-child {
     font-size: 14px;
     color: #666;
}
.info-txt .price-wrap span[data-v-97d4705e]:last-child {
     font-size: 20px;
     color: #FC583D;
}
.info-txt .info-do[data-v-97d4705e] {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: start;
         -ms-flex-pack: start;
             justify-content: flex-start;
     -webkit-box-align: end;
         -ms-flex-align: end;
             align-items: flex-end;
}
.info-txt .info-do span[data-v-97d4705e]{
     font-size: 12px;
     color: #666;
}
.info-txt .info-do .join-btn[data-v-97d4705e] {
     width: 120px;
     height: 40px;
     color: #fff;
     margin-right: 20px;
}
.info-img[data-v-97d4705e] {
     width: 470px;
     height: 100%;
     margin-right: 20px;
}
.info-img img[data-v-97d4705e] {
     -o-object-fit: cover;
        object-fit: cover;
}
.classes-content[data-v-97d4705e] {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: start;
         -ms-flex-pack: start;
             justify-content: flex-start;
     -webkit-box-align: start;
         -ms-flex-align: start;
             align-items: flex-start;
}
.classes-content .side-bar[data-v-97d4705e] {
     width: 200px;
     min-width: 200px;
     border: 1px solid #EBEFF2;
     padding-top: 20px;
     border-radius: 4px;
     margin-right: 20px;
}
.classes-content .side-bar li[data-v-97d4705e] {
     height: 34px;
     margin-bottom: 20px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: center;
         -ms-flex-pack: center;
             justify-content: center;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     cursor: pointer;
}
.ac[data-v-97d4705e] {
     background-image: -webkit-gradient(linear, left top, right top, from(#fff) , to(#cde4ff));
     background-image: linear-gradient(to right, #fff , #cde4ff);
}
.classes-content .side-bar li .cicon[data-v-97d4705e] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.classes-content .side-bar li span[data-v-97d4705e] {
    font-size: 14px;
    line-height: 14px;
}
.classes-content .class-view[data-v-97d4705e] {
     -webkit-box-flex: 1;
         -ms-flex: 1;
             flex: 1;
     -webkit-box-sizing: border-box;
             box-sizing: border-box;
     border-radius: 4px;
}
.class-sign[data-v-97d4705e] {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: start;
         -ms-flex-pack: start;
             justify-content: flex-start;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     margin-bottom: 15px;
}
.class-sign li[data-v-97d4705e] {
     margin-right: 30px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
}
.class-sign li img[data-v-97d4705e] {
     width: 16px;
     height: 14px;
     margin-right: 5px;
}
.class-sign li span[data-v-97d4705e] {
     color: #666;
     font-size: 12px;
}
