
.error[data-v-53f1073e] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    color: #ddd;
}
.img[data-v-53f1073e] {
    width: 50px;
    height: 50px;
}
