
.exam-icon[data-v-6e5584be] {
  width: 72px;
  height: 63px;
}
.exam-item-wrap[data-v-6e5584be] {
  padding: 0 30px;
}
.exam-btn-wrap[data-v-6e5584be] {
  padding: 20px 0;
  text-align: center;
}
.exam-item[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  min-height: 104px;
}
.detailWrap .tabWrap[data-v-6e5584be] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.detailWrap[data-v-6e5584be] {
    margin-bottom: 30px;
}
.detailWrap .tab[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
}
.detailWrap .tab .item[data-v-6e5584be] {
  cursor: pointer;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #eee;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.detailWrap .tabContent[data-v-6e5584be] {
    border: 1px solid #eee;
    margin-right: 20px;
    padding: 0 30px 20px;
    width: 830px;
}
.detailWrap .tabContent .catalog[data-v-6e5584be] {
    width: 100%;
}
.detailWrap .details[data-v-6e5584be] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.directory-title-wrap[data-v-6e5584be] {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.directory-icon img[data-v-6e5584be]:first-child {
  width: 10px;
  height: 10px;
}
.directory-icon img[data-v-6e5584be]:last-child {
  width: 16px;
  height: 16px;
  margin-left: -7px;
}
.directory-title[data-v-6e5584be] {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.directory-second-item[data-v-6e5584be] {
  cursor: pointer;
  margin-left: 15px;
  border-bottom: 1px dashed #A6B7BF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* margin-bottom: 20px; */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
}
/* .directory-second-item-a:hover .directory-second-right button{
  display: block!important;
} */
/* .directory-second-item-a:hover .directory-second-right span {
  display: none!important;
} */
.directory-second-left[data-v-6e5584be] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.directory-second-right[data-v-6e5584be] {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
}
.directory-second-right button[data-v-6e5584be] {
  width: 80px;
  height: 30px;
  font-size: 12px;
  border-radius: 15px;
  padding: 0;
  outline: none;
  border: none;
  /* display: none; */
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.directory-second-right span[data-v-6e5584be] {
  color: #666;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
}
.directory-second-title[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* margin-bottom: 10px; */
}
.directory-second-title img[data-v-6e5584be] {
  width: 16px;
  height: 16px;
}
.directory-second-subT[data-v-6e5584be] {
  font-size: 12px;
  color: #666;
  padding-left: 20px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.detailWrap .title[data-v-6e5584be] {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    padding: 20px 0;
    text-align: left;
    margin-bottom: 0;
    position: relative;
}
.detailWrap .title[data-v-6e5584be]::before {
  content: '';
  width: 4px;
  height: 12px;
  border-radius: 2px;
  background: #3091FD;
  position: absolute;
  left: -10px;
  top: 24px;
}
.detailWrap .txt[data-v-6e5584be] {
    text-align: left;
    text-indent: 2em;
    margin-bottom: 20px;
    color: #333;
    font-size: 12px;
    line-height: 20px;
}
.exams ul li[data-v-6e5584be]{
    border: 1px solid #eee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;
    height: 160px;
}
.exams ul li h3[data-v-6e5584be] {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
}
.exams ul li .left[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.exams ul li .left p[data-v-6e5584be] {
  margin-bottom: 0;
  font-size: 12px;
  color: #888;
  text-align: left;
  margin-bottom: 15px;
}
.exams ul li .left p span[data-v-6e5584be] {
  padding-right: 10px;
}
.exams ul li button[data-v-6e5584be] {
  border: none;
  font-size: 14px;
}
.exams ul li .pass_icon[data-v-6e5584be] {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  height: 24px;
  width: 80px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
}
.certificate[data-v-6e5584be] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.certificate-wrap[data-v-6e5584be] {
    width: 500px;
    margin-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
}
.down[data-v-6e5584be] {
    cursor: pointer;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-right: 1px solid #eee;
    background: rgb(0, 113, 245);
    color: rgb(255, 255, 255);
    display: block;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 4px;
}
.beautifull[data-v-6e5584be] {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 0;
    color: #333;
}
.side-bar-wrap[data-v-6e5584be] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.star-wrap[data-v-6e5584be] {
  height: 230px;
  border: 1px solid #EBEFF2;
  padding: 0 20px;
  margin-bottom: 20px;
  border-radius: 2px;
}
.star-wrap .star-module[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.star-wrap .star-txt[data-v-6e5584be] {
  font-size: 12px;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
}
.star-wrap .star-info[data-v-6e5584be] {
  font-size: 12px;
  text-align: left;
  color: #999;
  padding-top: 20px;
}
.empty[data-v-6e5584be] {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-6e5584be] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-6e5584be] {
  font-size: 12px;
  color: #666;
}
.more[data-v-6e5584be] {
  font-size: 12px;
  color: #666;
  cursor: pointer;
}
.teacher-wrap[data-v-6e5584be] {
  /* height: 260px; */
  border: 1px solid #EBEFF2;
  padding: 0 20px 10px;
  margin-bottom: 20px;
  border-radius: 2px;
}
.teacher-title-wrap[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.teacher-title-wrap>div[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.teacher-title-wrap .title-info[data-v-6e5584be] {
  margin-left: 10px;
  color: #666;
  font-size: 12px;
  width: 120px;
}
.teacher-content[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 56px;
}
.teacher-avatar[data-v-6e5584be] {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 15px;
}
.teacher-info[data-v-6e5584be] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.teacher-info span[data-v-6e5584be]:first-child {
  font-size: 14px;
  color: #333;
}
.teacher-info span[data-v-6e5584be]:last-child {
  font-size: 12px;
  color: #666;
}
.teacher-info-content[data-v-6e5584be] {
  font-size: 12px;
  color: #666;
  text-align: left;
  line-height: 22px;
  padding-top: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.recommended-wrap[data-v-6e5584be] {
  /* height: 410px; */
  border: 1px solid #EBEFF2;
  padding: 0 20px 10px;
  margin-bottom: 20px;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.recommended-wrap .course-items[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 74px;
  margin-bottom: 10px;
  cursor: pointer;
}
.recommended-wrap .course-info[data-v-6e5584be] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.recommended-wrap .course-items img[data-v-6e5584be]{
  margin-right: 15px;
  width: 132px;
  height: 74px;
  border-radius: 4px;
  -o-object-fit: cover;
     object-fit: cover;
}
.recommended-wrap .course-info span[data-v-6e5584be]:first-child {
  font-size: 14px;
  color: #333;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.recommended-wrap .course-info span[data-v-6e5584be]:last-child {
  font-size: 12px;
  color: #666;
}
.qes-wrap[data-v-6e5584be] {
  /* height: 355px; */
  border: 1px solid #EBEFF2;
  padding: 0 20px 10px;
  margin-bottom: 20px;
  border-radius: 2px;
}
.qes-wrap .news-item[data-v-6e5584be] {
  font-size: 12px;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.qes-wrap .news-item[data-v-6e5584be]:hover {
  color: #3091FD;
  cursor: pointer;
}
.download-item[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px dashed #A6B7BF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.download-item img[data-v-6e5584be] {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.download-item-left[data-v-6e5584be], .download-item-right[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.download-item-left span[data-v-6e5584be] {
  font-size: 14px;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.download-item-right span[data-v-6e5584be] {
  font-size: 12px;
  color: #666;
  margin-right: 20px;
}
.download-item-right a[data-v-6e5584be] {
  width: 80px;
  display: block;
  height: 24px;
  line-height: 24px;
  background: #0071F5;
  color: #fff;
  font-size: 12px;
  padding: 0;
  border-radius: 12px;
  border: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABFUlEQVQ4T7WTLUtEQRiFnyeKUewaFDEJalDEj2AzWPwFgsWgRSxmo4v4BywGsYiCFoMIYtlsMljEZNEsR+6yV9a764IsDgzDvHPmmfO+MyM9NnvcT0dAkjWg6DX1odshbYAkAY6BK2AXGFYHfoP8ACSpq1Ot4iSzwH1LbEm9LeffgCQbQL9a62Y5yQuwrZ4VugYgyRywDxyVC834ItCnXldcRW3sNckBsA6Mqa8V4QIwoR5WAcCyelMAiqKNqE8V0TgwCIwWNVAfK+uXwEoDUNopBUmGgHNgqwnZA1bV57ZUOgFaQHeNPHW+U2GTvBUOLoBT9eQvrzLJNLBT3kIdmGwCPoB3oNs4A3wCm//zF/6SSs8OvgA083a+q+WbLQAAAABJRU5ErkJggg==);
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 16px 16px;
}
.teacher-infos[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.teacher-infos>img[data-v-6e5584be] {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 15px;
}
.teacher-infos>div[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.teacher-infos>div>span[data-v-6e5584be]:first-child {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}
.teacher-infos>div>span[data-v-6e5584be]:last-child {
  font-size: 12px;
  color: #666;
}
.teacher-scroll[data-v-6e5584be] {
  overflow-y: auto;
  height: 480px;
  background: #FAFAFA;
  padding:20px;
}
.teacher-scroll[data-v-6e5584be]::-webkit-scrollbar {
  width:3px;
}
.teacher-scroll[data-v-6e5584be]::-webkit-scrollbar-thumb {
  background-color:#aaa;
}
.teacher-items[data-v-6e5584be] {
  margin-bottom: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}
.teacher-items>div[data-v-6e5584be] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
.teacher-items>p[data-v-6e5584be] {
  font-size: 12px;
  color: #333;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.black[data-v-6e5584be] {
  width: 100%;
  height: 360px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
}
@media screen and (max-width: 1024px) {
.detailWrap .tabWrap[data-v-6e5584be] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
}
.detailWrap .tabContent[data-v-6e5584be] {
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px;
}
.detailWrap .tab .item[data-v-6e5584be] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
}
.side-bar-wrap[data-v-6e5584be] {
      width: 100%;
}
}
.icon[data-v-6e5584be] {
  font-size: 12px;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 18px;
  height: 18px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.mp4-color[data-v-6e5584be] {
  color: rgb(8, 128, 241);
  border: 1px solid rgb(8, 128, 241);
}
.mp3-color[data-v-6e5584be] {
  color: #52c41a;
  border: 1px solid #52c41a;
}
.doc-color[data-v-6e5584be] {
  color: #faad14;
  border: 1px solid #faad14;
}
.btn-txt-info[data-v-6e5584be] {
  font-size: 12px;
  color: #c00;
  line-height: 28px;
  padding: 0!important;
  display: inline-block;
}
.exam-start-wrap[data-v-6e5584be] {
  padding: 20px;
}
.exam-top[data-v-6e5584be] {
  background: #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}
.warn-icon[data-v-6e5584be] {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.warn-icon span[data-v-6e5584be] {
  color: #333;
  font-size: 14px;
}
.start-txt[data-v-6e5584be] {
  font-size: 15px;
  color: #333;
  line-height: 1;
  margin-bottom: 15px;
}
.start-do-wrap[data-v-6e5584be] {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
