
.bg[data-v-79537894] {
    width: 100%;
    height: 560px;
    background: url(../img/allianceBg.e12f0b88.jpg) no-repeat center center;
    background-size: cover;
}
.inner[data-v-79537894] {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 60px;
}
.inner p[data-v-79537894] {
    width: 490px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 70px;
}
.btn[data-v-79537894] {
    widows: 126px;
    height: 42px;
    border-radius: 10px;
    background: #0066CC;
    font-size: 15px;
    color: #fff;
    border: none;
}
.thr1x[data-v-79537894] {
    height: 500px;
    padding-top: 50px;
}
.thr1x ul[data-v-79537894] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.thr1x ul li[data-v-79537894] {
    width: 288px;
    height: 310px;
    background: #fff;
    border-radius: 10px;
    margin: 0 12px;
    padding: 40px 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
    -webkit-box-shadow: 0 0 10px #eee;
            box-shadow: 0 0 10px #eee;
}.thr1x ul li[data-v-79537894]:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.thr1x ul li img[data-v-79537894] {
    width: 60px;
    height: 60px;
    margin-bottom: 26px;
}
.thr1x ul li h3[data-v-79537894] {
    color: #111D30;
    font-size: 18px;
    margin-bottom: 18px;
    line-height: 18px;
    font-weight: bold;
}
.thr1x ul li p[data-v-79537894] {
    color: #666;
    text-align: left;
    font-size: 12px;
    line-height: 24px;
}
.title1x[data-v-79537894] {
    color: #111D30;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 45px;
}
.service[data-v-79537894] {
    padding-top: 50px;
    height: 304px;
    background: url(../img/serBg.0d8f934b.png) no-repeat;
}
.service .title[data-v-79537894] {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 60px;
    color: #fff;
}
.service ul[data-v-79537894] {
    width: 1200px;
    margin: 0 auto;
    padding: 0 170px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.service ul li[data-v-79537894]{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.service ul li>div[data-v-79537894] {
    margin-bottom: 14px;
}
.nStyle[data-v-79537894] {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
    line-height: 40px;
}
.sStyle[data-v-79537894] {
    font-size: 20px;
    color: #fff;
    line-height: 20px;
}
.fou1x[data-v-79537894] {
    padding-top: 50px;
    background: #f6f8fb;
    height: 600px;
}
.fou1x ul[data-v-79537894] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.fou1x ul li[data-v-79537894] {
    margin: 0 10px;
}
.fou1x ul li img[data-v-79537894] {
    width: auto;
    height: auto;
}
.bg-title[data-v-79537894] {
    font-size: 38px;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top, #fff, #eee, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
