
.inner[data-v-3dac46d7] {
        width: 1200px;
        margin: 0 auto;
}
.detail[data-v-3dac46d7] {
        padding-bottom: 20px;
        text-align: left;
        display: block;
        padding:0 0 20px
}
.detail .title[data-v-3dac46d7] {
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 0;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 1200px;
}
.detail .time[data-v-3dac46d7] {
        color: #999;
        margin-bottom: 20px;
        text-align: left;
}
.detail .body[data-v-3dac46d7] {
        padding: 10px;
        border-radius: 4px;
        overflow: hidden;
        min-height: 600px;
        text-align: left;
}
@media screen and (max-width: 1024px) {
.detail[data-v-3dac46d7] {
            width: 100%;
}
}
.news-tab-wrap[data-v-3dac46d7] {
  height: 60px;
  -webkit-box-shadow: 0 3px 5px #ccc;
          box-shadow: 0 3px 5px #ccc;
  margin-bottom: 20px;
}
.news-tab[data-v-3dac46d7] {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.news-tab li[data-v-3dac46d7] {
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
  cursor: pointer;
  text-align: left;
  border-bottom: 2px solid #fff;
}
.title-bg[data-v-3dac46d7] {
    height: 130px;
    background: url(../img/title-bg.cd4e80a0.png) no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.newbg[data-v-3dac46d7]{
    background: url(../img/class_newbg.ecc6569c.jpg) no-repeat;
    background-size: cover;
}
.title-inner[data-v-3dac46d7] {
  width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title-inner img[data-v-3dac46d7] {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.title-inner h3[data-v-3dac46d7] {
  font-size: 30px;
  margin-bottom: 0;
  background: -webkit-gradient(linear, left top, right top, from(#DCFBFE), to(#6EA2DF));
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  -webkit-background-clip: text;
  color: transparent;
}
.title-wrap-modal[data-v-3dac46d7] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
