
.player-mask[data-v-765344e6] {
  position: absolute;
  left: 0;
  top: 66px;
  width: 100%;
  background: rgba(0, 0, 0, .8);
  z-index: 99999999999999999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title-mask[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}
.title-mask img[data-v-765344e6]{
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.title-mask h3[data-v-765344e6] {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
}
.player-mask p[data-v-765344e6] {
  color: #fff;
}
.air-msg[data-v-765344e6] {
  width: 340px;
  background: #fff;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  bottom: 140px;
  padding: 5px 10px;
  -webkit-transition: all .5s;
  transition: all .5s;
}
.air-msg img[data-v-765344e6] {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.air-msg p[data-v-765344e6] {
  font-size: 24rpx;
  color: #888;
  margin-bottom: 0;
}
.player-item-wrap[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.player-custom-controller[data-v-765344e6] {
  position: absolute;
  left: 75px;
  bottom: 80px;
  z-index: 9999999999999999;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.player-icon-controller[data-v-765344e6] {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 5px;
}
.player[data-v-765344e6] {
    width: 1200px;
    margin: 0 auto;
    padding: 0!important;
}
.player .ant-breadcrumb[data-v-765344e6]{
    text-align: left;
    margin: 10px 0;
    width: 450px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.player .errTips[data-v-765344e6] {
    width: 100%;
    height: 100%;
    background: #333;
    color: #fff;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.player .errTips span[data-v-765344e6] {
  margin-bottom: 10px;
}
.player .errTips button[data-v-765344e6] {
  border: none;
}
.player .playerWrap[data-v-765344e6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
}
.online-icon[data-v-765344e6] {
  background: rgba(0, 0, 0, .5);
  filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#7F000000,endcolorstr=#7F000000);
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: 76px;
  padding: 5px 8px;
  border-radius: 4px;
  z-index: 99999999999999;
}
.player-inner[data-v-765344e6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.prism-player[data-v-765344e6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.player-normal-menu[data-v-765344e6] {
  background: #212121;
  height: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  -webkit-transition: all .5s;
  transition: all .5s;
}
.progress-wrap[data-v-765344e6] {
  padding: 30px 20px;
}
.progress-title[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #999;
  font-size: 16px;
  margin-bottom: 20px;
}
.player-menu-title-wrap[data-v-765344e6] {
  height: 20px;
  padding-left: 20px;
  text-align: left;
  margin-bottom: 15px;
}
.player-menu-title[data-v-765344e6] {
  width: auto;
  color: #ccc;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  font-size: 14px;
}
.player-normal-item[data-v-765344e6] {
  padding-bottom: 10px;
}
.player-normal-item-scroll[data-v-765344e6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0;
}
.player-normal-item-wrap[data-v-765344e6] {
  padding: 0 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto;
}
.player-normal-item .chapter[data-v-765344e6] {
  color: #999;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  text-align: left;
  margin-bottom: 15px;
}
.item-wrap-flex[data-v-765344e6] {
  padding: 0 10px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.item-wrap-flex .course-time[data-v-765344e6] {
  text-align: left;
  padding-left: 10px;
  color: #666;
  font-size: 12px;
  height: 12px;
  margin-bottom: 0;
  line-height: 12px;
}
.item-title-wrap[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.item-title-wrap img[data-v-765344e6] {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.item-title-wrap p[data-v-765344e6] {
  color: #999;
  font-size: 14px;
  margin: 0;
  line-height: 22px;
  height: 22px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 260px;
}
.player-menu-title[data-v-765344e6]::before {
  content: '';
  width: 4px;
  border-radius: 2px;
  left: -10px;
  top: 4px;
  height: 12px;
  background: #3091FD;
  display: block;
  position: absolute;
}
.player-inner-title[data-v-765344e6] {
  background: #151515;
  height: 66px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  z-index: 9999999999999999;
}
.user_info[data-v-765344e6]{
  font-size: 18px;
  color:#ffffff;
  padding-right: 20px;
}
.player-back[data-v-765344e6] {
  cursor: pointer;
  width: 100px;
  background: #212121;
  height: 66px;
  line-height: 66px;
}
.player-back img[data-v-765344e6] {
  width: 32px;
  height: 32px;
}
.player-inner-title p[data-v-765344e6] {
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 0 20px;
}
.player-bot-do[data-v-765344e6] {
  background: #151515;
  height: 80px;
  min-height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
  width: 100%;
  position: relative;
}
.player-do-btn button[data-v-765344e6] {
  background: #151515!important;
  border: 1px solid #fff!important;
  margin-left: 20px;
  height: 36px;
  width: 98px;
}
.player-do-btn button[data-v-765344e6]:hover {
  background: #0071F5!important;
  border: none!important;
}
.player .playerModules[data-v-765344e6] {
    width: 400px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    z-index: 9;
    position: relative;
    -webkit-transition: all .5s;
    transition: all .5s;
}
.player .tab[data-v-765344e6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid #eee;
    background: #fff;
}
.player .tab li[data-v-765344e6] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.player .player-content[data-v-765344e6] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background: #e9ebf5;
    padding-bottom: 10px;
}
.checkWrap[data-v-765344e6] {
    margin-bottom: 10px;
}
.player .player-list[data-v-765344e6]{
    /* height: 100%; */
    padding:8px;
    overflow: auto;
}
.player .player-item[data-v-765344e6] {
    background: #fff;
    height: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    cursor: pointer;
    margin-bottom: 10px;
}
.player .playback[data-v-765344e6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 15px;
}
.player .playback .playback-icon[data-v-765344e6] {
    display: block;
    width: 46px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid #aaa;
    margin-right: 10px;
    font-size: 12px;
}
.player .playback .title[data-v-765344e6] {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.classes-test[data-v-765344e6]::-webkit-scrollbar, .player-normal-item-wrap[data-v-765344e6]::-webkit-scrollbar, .qes-talk-room[data-v-765344e6]::-webkit-scrollbar, .txtWrap[data-v-765344e6]::-webkit-scrollbar, .player-list[data-v-765344e6]::-webkit-scrollbar {
    width:3px;
}
.classes-test[data-v-765344e6]::-webkit-scrollbar-thumb, .player-normal-menu[data-v-765344e6]::-webkit-scrollbar-thumb, .qes-talk-room[data-v-765344e6]::-webkit-scrollbar-thumb, .txtWrap[data-v-765344e6]::-webkit-scrollbar-thumb, .player-list[data-v-765344e6]::-webkit-scrollbar-thumb{
    background-color:#aaa;
}
.player .talkingItem[data-v-765344e6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.player .talkingInfo[data-v-765344e6] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.player .talkingRoom[data-v-765344e6] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.player .talkingRoom .pho[data-v-765344e6] {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
}
.player .talkingInfo .name[data-v-765344e6] {
    font-weight: bold;
    line-height: 14px;
    padding: 18px 10px;
    display: block;
}
.player .talkingInfo .txt[data-v-765344e6] {
    background: #fff;
    border-radius: 6px;
    text-align: left;
    padding: 10px 15px;
    margin-bottom: 0;
}
.player .sendWrap[data-v-765344e6] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 8px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.player .txtWrap[data-v-765344e6] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: auto;
    padding: 8px;
    max-height: 80vh;
}
.player .txtWrap>div[data-v-765344e6] {
  padding-bottom: 40px;
}
.player .sendBtn[data-v-765344e6] {
    margin-left: 8px;
    height: 40px;
    color: #fff;
    width: 80px;
}
.player .sendTxt[data-v-765344e6] {
    height: 40px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.logo[data-v-765344e6] {
    width: 140px;
    height: 50px;
    margin: 0 auto 30px;
}
.notice[data-v-765344e6] {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    text-indent: 2em;
    color: #333;
}
.answer-item[data-v-765344e6] {
  background: #Fff;
  padding:15px;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.player .top[data-v-765344e6] {
    height: 68px;
    background: rgb(251, 191, 48);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 18px;
    z-index: 99999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.player .top p[data-v-765344e6] {
    color: rgb(167, 118, 0);
    margin-bottom: 0;
    margin-right: 30px;
}
.player .top a[data-v-765344e6] {
    color: #fff;
}
.player .noMsgBtn[data-v-765344e6] {
    width: 100px;
    height: 32px;
    background: #fff;
    color: rgb(167, 118, 0)!important;
    border-radius: 16px;
    font-size: 16px;
    cursor: pointer;
    border: 0;
}
.signal[data-v-765344e6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 9999;
    min-width: 350px;
}
.signalColor[data-v-765344e6] {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
}
.btn-wrap[data-v-765344e6] {
  text-align: center;
}
.qes-talk-wrap[data-v-765344e6] {
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.qes-talk-room[data-v-765344e6] {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto;
}
.qes-do-wrap[data-v-765344e6] {
  border-radius: 6px;
  width: 440px;
  height: 110px;
  border: 1px solid #E1E8EB;
}
.qes-talk-avatar[data-v-765344e6] {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin-bottom: 5px;
}
.qes-talk-info[data-v-765344e6] {
  margin-top: 17px;
  background: #FAFAFA;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.qes-talk-info p[data-v-765344e6] {
  color: #666;
}
.qes-talk-info span[data-v-765344e6] {
  color: #999;
}
.qes-people-wrap[data-v-765344e6] {
  min-width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.qes-talk-name[data-v-765344e6] {
  font-size: 12px;
}
.qes-talk-item[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 20px;
}
.qes-do-wrap[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding:10px;
}
.qes-do-wrap .qes-txt[data-v-765344e6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  outline: none;
  border: none;
  resize: none;
}
.qes-do-wrap button[data-v-765344e6] {
  width: 88px;
  height: 36px;
}
.test-title[data-v-765344e6] {
  background: #FAFAFA;
  padding: 20px 20px 0;
}
.qesIcon[data-v-765344e6] {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}
.test-title>span[data-v-765344e6] {
  color: #1D75FA;
}
.test-title>span[data-v-765344e6]:first-child {
  font-size: 22px;
}
.classes-test[data-v-765344e6] {
  height: 480px;
  overflow-y: auto;
  background: #FAFAFA;
  padding:20px;
}
.classes-test>h3[data-v-765344e6] {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.refresh[data-v-765344e6] {
  font-size: 16px;
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}
.exam-btn-wrap[data-v-765344e6] {
  text-align: center;
  padding: 25px 0;
}
.sidebar-btn[data-v-765344e6] {
  width: 18px;
  height: 60px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #555;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  margin-top: -30px;
}
.sidebar-btn img[data-v-765344e6] {
  width: 16px;
  height: 16px;
}
@media screen and (max-width: 1024px) {
.player-inner-title[data-v-765344e6] {
    height: 50px;
}
.player-back[data-v-765344e6] {
    width: 50px;
}
.player-inner[data-v-765344e6] {
    height: 370px;
}
.signal[data-v-765344e6] {
    font-size: 12px;
}
.playerWrap[data-v-765344e6] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: #333;
}
.player-do-btn button[data-v-765344e6] {
    width: 78px;
    height: 28px;
    font-size: 12px;
    margin-left: 10px;
}
.player-inner[data-v-765344e6] {
    margin-bottom: 20px;
}
.player-normal-menu[data-v-765344e6] {
    width: 100%;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.qes-talk-wrap[data-v-765344e6] {
    height: 400px;
}
.qes-do-wrap[data-v-765344e6] {
    width: 100%;
}
.bot-div[data-v-765344e6] {
    display: none;
}
.player .txtWrap[data-v-765344e6] {
    max-height: 30vh;
}
.player .playerModules[data-v-765344e6] {
    width: 100%;
}
.player-bot-do[data-v-765344e6] {
    min-height: 60px!important;
    height: 60px!important;
}
}
.mmm[data-v-765344e6] {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, .5);
  z-index: 999999999999;
}
.pdf[data-v-765344e6] {
  width: 100%;
  overflow: auto;
}
.tools[data-v-765344e6] {
  margin: 0 auto;
}
.tools button[data-v-765344e6] {
  height: 30px;
  background: rgb(0, 113, 245);
  color: #fff;
  border: 0;
}
.page[data-v-765344e6] {
  padding-top: 5px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}
.pdf[data-v-765344e6]::-webkit-scrollbar {
  width: 3px;
}
.pdf[data-v-765344e6]::-webkit-scrollbar-thumb {
  background: #aaa;
}
.sub-wrap[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.icon[data-v-765344e6] {
  font-size: 12px;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 18px;
  height: 18px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  color: #888;
  border: 1px solid #888;
  margin-bottom: 0;
}
.cover-mp3[data-v-765344e6] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.cover-mp3 img[data-v-765344e6] {
  -o-object-fit: cover;
     object-fit: cover;
}
.people-module[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.people-title[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.item-bg[data-v-765344e6] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}
.item-bg p[data-v-765344e6] {
  margin-bottom: 0;
}
.people-title h3[data-v-765344e6] {
  font-weight: bold;
  margin-bottom: 0;
}
.people-title img[data-v-765344e6] {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.try-content[data-v-765344e6] {
  padding: 20px 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.try-btn-wrap[data-v-765344e6] {
  padding-top: 10px;
}
.try-btn-wrap button[data-v-765344e6] {
  margin: 0 10px;
}
.exam-content[data-v-765344e6] {
  padding: 20px 20px 0 20px;
}
.exam-do-wrap[data-v-765344e6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.code[data-v-765344e6]{
  position: relative;
  top: 3px;
}
.code_img[data-v-765344e6]{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.code_img_text[data-v-765344e6]{
  margin-left: 20px;
  color: blue;
  text-decoration:underline;
  cursor: pointer;
}
.code_input[data-v-765344e6]{
  width: 100px;
  margin-right: 10px;
}
.prompt-wrap_content[data-v-765344e6]{
  margin:0 auto;
  width:270px;
}
.prompt-wrap_redtext[data-v-765344e6]{
  color:red
}
