
.select-wrap[data-v-10433372] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.link-item[data-v-10433372] {
    border-bottom: 1px solid #eee;
    padding: 20px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.link-item img[data-v-10433372] {
    width: 66px;
    height: 66px;
    border-radius: 33px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-right: 15px;
    border: 1px solid #eee;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.link-user[data-v-10433372] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 15px;
}
.user[data-v-10433372] {
    font-weight: bold;
    text-align: left;
}
.user-info[data-v-10433372] {
    font-size: 14px;
    color: #666;
}
.infos[data-v-10433372] {
    color: #666;
    font-size: 14px;
}
.sign[data-v-10433372] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.sign span[data-v-10433372] {
    display: block;
}
.sign .sign-see[data-v-10433372] {
    color: #666;
    font-size: 12px;
    margin-right: 30px;
}
.sign .sign-do[data-v-10433372] {
    color: #fff;
    background: rgb(0, 113, 245);
    padding:4px 8px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}
.empty[data-v-10433372] {
  padding: 50px 0;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-10433372] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-10433372] {
  font-size: 12px;
  color: #666;
}
