
.top-bar[data-v-69888ff5] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 1px solid #D0D7E1;
    height: 92px;
    width: 1200px;
    margin: 0 auto;
    background: #fff;
}
.form-item[data-v-69888ff5] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 50px;
}
.form-item label[data-v-69888ff5] {
    min-width: 60px;
    font-size: 12px;
    color: #333;
}
.btns[data-v-69888ff5] {
    background: #0066CC;
    color: #fff;
    border-radius: 8px;
    border: none;
}
.xcert[data-v-69888ff5] {
    background: #F6F8FB;
    height: 100%;
}
.bg[data-v-69888ff5] {
    height: 150px;
    margin-bottom: 30px;
    background: url(../img/bg-title-1x.8f9cc269.png) no-repeat;
    background-size: cover;
}
.bg-title[data-v-69888ff5] {
    font-size: 38px;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top, #fff, #eee, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    margin-bottom: 10px;
}
.title[data-v-69888ff5] {
    font-size: 20px;
    color: #000;
    font-weight: bold;
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    padding: 30px 20px;
}
.inner[data-v-69888ff5] {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 100%;
}
.cert-content[data-v-69888ff5] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto 20px;
    background: #fff;
    padding: 0 20px;
}
.cert-item[data-v-69888ff5] {
    width: 570px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px solid #eee;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.cert-item[data-v-69888ff5]:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}
.cert-item[data-v-69888ff5]:nth-child(2n) {
    margin-right: 0;
}
.top[data-v-69888ff5] {
    width: 100%;
    height: 130px;
    background-size: cover!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 0 20px;
    position: relative;
}
.top h3[data-v-69888ff5] {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 60%;
    text-align: left;
}
.btn[data-v-69888ff5] {
    height: 20px;
    line-height: 20px;
    border-radius: 6px;
    color: #eee;
    border: 1px solid #eee;
    background: none;
    padding: 0 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 18px;
}
.cert-img[data-v-69888ff5] {
    width: 180px;
    height: 130px;
    border-radius: 5px;
    background: #fff;
    padding: 5px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(7, 0, 2, 0.2);
            box-shadow: 0px 5px 10px 0px rgba(7, 0, 2, 0.2);
}
.mask[data-v-69888ff5] {
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;
    height: 140px;
}
.bot[data-v-69888ff5] {
    height: 125px;
    background: #fff;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    position: relative;
}
.bot p[data-v-69888ff5] {
    margin-bottom: 0;
    line-height: 16px;
    font-size: 12px;
    color: #999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.bot p span[data-v-69888ff5] {
    color: #000;
}
.cert-icon[data-v-69888ff5] {
    background: #0066CC;
    width: 30px;
    border-radius: 15px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}
