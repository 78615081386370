
.list-box[data-v-497362cb]{
    -webkit-box-shadow: -100px 0px 30px 0px rgba(0, 0, 0, 0) inset,0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset,100px 0px 30px 0px rgba(0, 0, 0, 0) inset,0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset;
            box-shadow: -100px 0px 30px 0px rgba(0, 0, 0, 0) inset,0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset,100px 0px 30px 0px rgba(0, 0, 0, 0) inset,0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset;
    padding:10px 0;
}
.list-module[data-v-497362cb]{
    width:1200px;
    margin:0 auto;
}
.inner[data-v-497362cb] {
    width: 1200px;
    margin: 0 auto;
}
.top-wrap[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 415px;
    padding-top: 20px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    margin-bottom: 20px;
}
.banner[data-v-497362cb] {
    width: 740px;
    height: 100%;
    margin-right: 20px;
    border-radius: 5px;
    overflow: hidden;
}
.news[data-v-497362cb] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 100%;
}
.diagonal-line[data-v-497362cb]{
    padding:0 10px;
    color: #cccccc;
}
.diagonal-line2[data-v-497362cb]{
    padding:0 4px;
    color: #333;
}
.title-bar[data-v-497362cb] {
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
.title-bar img[data-v-497362cb] {
    margin-right: 10px;
}
.title-wrap[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.title-wrap span[data-v-497362cb] {
    font-size: 24px;
    line-height: 25px;
    color: #1A1A1A;
    font-weight: bold;
}
.title-bar .more[data-v-497362cb] {
    font-size: 14px;
    height: 26px;
    line-height: 26px;
    color: #999;
    cursor: pointer;
}
.carouselImgWrap img[data-v-497362cb] {
    /* height: 420px; */
    -o-object-fit: cover;
       object-fit: cover;
}
.news[data-v-497362cb] {
    height: 420px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-top:7px;
}
.news-wrap[data-v-497362cb] {
    height: 412px;
}
.news .date[data-v-497362cb] {
    width: 68px;
    height: 68px;
    background: #F7F7F7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    border-radius: 5px;
}
.news .date-day[data-v-497362cb] {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    line-height: 36px;
}
.news .dates[data-v-497362cb] {
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    color: #000;
}
.news li[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 18px;
    height: 68px;
}
.news li[data-v-497362cb]:last-child {
    margin-bottom: 0;
}
.news .news-item[data-v-497362cb] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    /* border-bottom: 1px solid #E6E6E6; */
    height: 68px;
    padding: 8px 0 8px 0;
}
.news-item .news-title[data-v-497362cb] {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    height: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 2px;
    cursor: pointer;
    text-align: left;
}
.news-item .news-title[data-v-497362cb]:hover {
    color: #D7221E;
}
.news-item .news-info[data-v-497362cb] {
    font-size: 14px;
    color:#767676;
    line-height: 20px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    text-align: left;
}
.flower[data-v-497362cb] {
    height: 130px;
    border: 1px solid #E6E6E6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 40px;
}
.flower-item[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flower-item>span[data-v-497362cb] {
    font-size: 14px;
    color: #2A2928;
    font-weight: bold;
    line-height: 14px;
}
.flower-item img[data-v-497362cb]{
    width: 54px;
    height: 54px;
    margin-bottom: 10px;
}
.flower .flower-sign[data-v-497362cb] {
    width: 17px;
    height: 21px;
}
.classes-list[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    /* padding-bottom: 40px; */
}
.classes-item[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /* padding-right: 15px; */
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    width: 585px;
    height: 150px;
    /* width: 1220px; */
    /* margin-left: -20px; */
    /* padding: 20px; */
    -webkit-transition: all .3s;
    transition: all .3s;
    margin-right: 30px; 
    margin-bottom: 30px;
    border: 1px solid #EEEEEE;
    background: #FFFFFF;
}
.classes-item[data-v-497362cb]:nth-child(2n) {
    margin-right: 0;
}
.classes-item:hover .classes-go[data-v-497362cb] {
    background: #0880F1;
}
.classes-item[data-v-497362cb]:last-child {
    /* margin-bottom: 0; */
}
.classes-item .img .classes-sign-ing[data-v-497362cb] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAAAlCAYAAAE7AYf8AAAAAXNSR0IArs4c6QAAA/tJREFUeAHtWstrE0EYn2+zzTtbrKBFRKwWKmgFwYNihVLoQfBQxKseRKwHFS96sc3LF+Kl+DhUqP4BngQfR8FTPehBinrxINqKFyVJm8S02XFm08+ma2P2lelm3YXkm5nMN9/3+347k3mBcrPcW6lUnhKTj6wuLoYIpX0m9YhkVgHri1eEcCb/Ec2bkbKlwACMWMJYTClPLClySBBO56gZbFgX4tnC7iVVncGCVkvLEK04Vk53glCD3EmhBqPZ/ClZDUk/SYk+tBIiszqqSg5Z7lFmjWn1KX1pqSdaMsaU2EuzSyiH3FFHDb44GW0K3lGDgz0yKaUU7aO3zMo1W44ZnB6N6W2syu95TDp4gbyq1EZmQwRIVa0Nr7OFv4fZT+/zv1jz4JjBvol5Q+46FlJD1jjEcDo/ZbSy3Xps5P4g/O/JrtOm9OXgduEUmnLQRmUA+F4ei3z2LEA2rnXz+LgaIB+U65+Z8/H6bMO0BCSDP7oaIDqJQHd2GXO3mOpMo64xDawtSCIgq+Yi6dxd1PXsKMrn9xykKxnE6NuR4UxOmwp4FiChJBbNLuwTu6SwQ4kFXaHLFwv+2VLx7KvJ/gOvexbcQlIZ9yw4NvekHgUHz3lndS24LrZUHtgWsDSggCyfcTW4/s0SGe5dWeU/OhYxDLQ0Fp11NThEcmkgSPZvkYjRiTQQmENd176W6GA8CCQe0qaMWPRPyQaSa1jBleDuHQ0TMI4HsWhSJeotLHAluL3dAXYCgy6alJQkUMOV4L7kVPKNbSZyyT+z+VoanW4mY9n5fl7H8slPMwPr+TsbVKZLaeWgK5mzGxhK6AHehifBcWC9d2jIs+C+/shPeBYcI+8sm9+AkJNE/qqIfjy7EyY6kKLtSSCd83K3Ex1PYfbYX/m7Yipx3ydPWMgdNCRRbUm+suZ1sG2/qRZGAGCqlFRecwt+z2thnJ1vGspd3YkL2K5PHkaiDaQEcHFuFIroqk8eRsKEHOwJaPfIMkOhhlodDkeW7aC9ZZOUyXqDDpuob/r/ScfYTbyhHSvnAa9Ox0h+fPWdFLvRAKl2blDfjk9efTQMpvV7rZcPh8izEzHCe2RLHoDJYjL2Rt+2T54+Ii7MAyUb13LLJ2+tqKxRxi948eFwPR62BX08lpkf1tv2ydNHxKX5Kq0+0Lvm723qI9IgH2WTkvISIctX+onCJpraDf7l+okgIYVKLRNgU8MQ2/4oLjZozGqxBFfKSeUGqvs9DyPRRHIikDhetZ44nkfieLrKDo8dJ443rJKr8duFTTzJH5+8Whza5JtK1QX6Z63nk9cmtKGbbPIyEs3kjvC8Tx5GpY0kpaBNXnzy2og0dJX1vq3hbCH5G1E1+USgzpwcAAAAAElFTkSuQmCC) no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 111px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img .classes-sign-end[data-v-497362cb] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAlCAYAAAGi+V5JAAAAAXNSR0IArs4c6QAAAgJJREFUaAXtma9OA0EQxrmjooI0QdaBq+MZqnA8AIaU4BpIUfSPIIg2TSrapCkOJAYFDwAOg0MTFArZIC5cuOObkiGXGmYv23SSzpq9S76bfvPbmcveNuh2u+maYIQCzUyyRGGwnGSCIDgUZZ2m6bVISCDFyXhfmGUFvBCzETo8L2BpzoRikUz3oiDb2CtDBKx4Ddhqtd68BqRVW7GAeMlVfaf8oL9TRP3pIPKN0OGn/5ei8caqDbbb7RO1BkHvhRirNVgsFquqDUZR9KraILZSm6oNkrler3entgbJICjued/SUGBfA538rpogEh2qNlguly9VG6zVapFqg1TLag2iQe5VG8QrZqTaYKfTeVRtkMzRUFuDv/bMIHNwn9EkAT2ldon7/f6BaoMg2NBucEe1QTJHQ20NkrnBYLCt2mAcxw3vZymUucfxpXrL7zHRhYTCjutWdQkuJGuPQcMwHBnAnEBRfc/NZvPJAOYEyN8jhZzPr/RjqL4PnPzeEASrwBylAICzr2EDmA/ed6lUGvOjVoFMQjjj3XdVr9c/WW4AmYRwRvsOs1IDmKUhuAbArazMAGZpCK6TJJmdc7DUADIJ+byL/+UqLDeATMJt/qtCA+gGjtVHk8lkg24MICNxmLGVWZ9Op8cG0AHavJQPze08cJ6Mwz2Os/athR2AzUtRhac/CjJ3JtjpeuYAAAAASUVORK5CYII=) no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 88px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img[data-v-497362cb] {
    width: 270px;
    height: 152.54px;
    margin-right: 20px;
    position: relative;
    border-radius: 5px 0px 0px 5px;
    overflow: hidden;
}
.classes-item .img img[data-v-497362cb] {
    -o-object-fit: cover;
       object-fit: cover;
}
.classes-title[data-v-497362cb] {
    font-size: 16px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 24px;
    height: 50px;
    margin-bottom: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
}
.classes-msg-wrap[data-v-497362cb] {
    height: 48px;
    font-size: 12px;
    line-height: 24px;
    color: #1A1A1A;
}
.classes-about[data-v-497362cb] {
    /* min-height: 72px; */
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.classes-sign[data-v-497362cb] {
    color: #FF5E57;
}
.classes-price[data-v-497362cb] {
    color: #FF5E57;
    font-size: 20px;
    font-weight: bold;
}
.classes-info[data-v-497362cb] {
    height: 150px;
    padding:15px 15px 15px 0px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.classes-do-wrap[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.classes-go[data-v-497362cb] {
    width: 86px;
    height: 34px;
    background: #F2F2F2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-transition: all .2s;
    transition: all .2s;
    overflow: hidden;
    border-radius: 2px;
}
.classes-item:hover .classes-go .classes-go-img[data-v-497362cb] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAKCAYAAAFJfdcxAAAAAXNSR0IArs4c6QAAAMtJREFUOBFjYMAG/v//Px2IUQCGOkaQNIYoLgGCioEKmGGamWAMLLQ4UKEwSJwY+8WxGIBfCGg6O8itQCCErBKfk5DVwdmMjIw/gZgRKMAGMe+/KFyS7gygC24A8VmKLYYadIEaBl0DGnYJZBAxUUnIwquEFFBFHujiRCA+BcSCVDGQEkOAjpAC4ltADALngBglzVJiNtl6gY6QBOLrQAwCF4FYBNkwUFy7AQUMkQXpwAYVBQVADMqQV4DYCZg3X4McEwDkWADxQINrAEvQi65+fmZHAAAAAElFTkSuQmCC) no-repeat;
}
.classes-go .classes-go-img[data-v-497362cb] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAKCAYAAAFJfdcxAAAAAXNSR0IArs4c6QAAAQ1JREFUOBFjYMACGNPT06f///8/A4scQogxLS3tP4JLgEVQ8apVq5hhZsAZMAEYLS4uLmlqasp2+vTp7wTtZ2JiEmeE6SSWzs3NZf/58+cPbm5u4f7+/ncwfSQbBNOYlZUl8efPn+fs7OxikydPfg0Tpy8NjNEbwCg4S7GtIIOA+ALFBgFdcw2IL4EMIhiVRNh2lewYIsJwuBKg1xOBOSOTmZnZffr06e/hEmgMJjQ+TbisrKw7GRkZBf7+/fsO6LBzhYWFQtgsokvIwCwGxqkkkL0PiDWAGBS/zrNmzXoDpMEAFNduQFcbwgToQQOjTBxoTwEQA61mvMLGxuYEyowgxwQABSzo4QgCdlwDAP2bUzjom0pHAAAAAElFTkSuQmCC) no-repeat;
    width: 35px;
    height: 10px;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.module-item[data-v-497362cb] {
    width: 100%;
    /* margin-bottom: 40px; */
}
.lesson-item[data-v-497362cb]:hover, .classes-item[data-v-497362cb]:hover {
    -webkit-box-shadow: 0 10px 30px rgba(0,0,0,0.2);
            box-shadow: 0 10px 30px rgba(0,0,0,0.2);
}
.cates-list[data-v-497362cb]{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.cates-item[data-v-497362cb] {
    text-align: right;
    font-size: 14px;
    height: 26px;
    line-height: 26px;
    color:#1A1A1A;
    cursor: pointer;
}
.lesson-list[data-v-497362cb] {
    /* box-sizing: content-box; */
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.lesson-item[data-v-497362cb] {
   
    -webkit-transition: all .3s;
   
    transition: all .3s;
    width: 277px;
    height: 280px;
    margin-right: 30px;
    background: #FFFFFF;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    /* padding: 15px; */
    margin-bottom: 28px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.lesson-item[data-v-497362cb]:nth-child(4n) {
    margin-right: 0;
}
.lesson-item img[data-v-497362cb] {
    height: 157.63px;
    -o-object-fit: cover;
       object-fit: cover;
    /* margin-bottom: 10px; */
    border-radius: 4px;
}
.lesson-title[data-v-497362cb] {
    /* margin-bottom: 25px; */
    font-size: 14px;
    line-height: 24px;
    height: 84.37px;   
    padding-top: 9.37px;
    padding-left: 14.84px;
    padding-right: 14.84px;
    padding-bottom: 25px;
    font-weight: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000000
}
.lesson-about[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    height: 40px;
    border-top: 1px solid #EEEEEE;
    font-size: 12px;
    line-height: 40px;
    color: #767676;
    padding-left: 14.84px;
    padding-right: 14.84px;
}
.lesson-name[data-v-497362cb] {
    min-height: 18px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-name span[data-v-497362cb] {
    height: 18px;
    line-height: 18px;
}
.lesson-price-info[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.lesson-msg-wrap[data-v-497362cb] {
    background: #FFFFFF;
    /* padding-top: 9.37px;
    padding-left: 14.84px;
    padding-right: 14.84px; */
}
.lesson-price-sign[data-v-497362cb] {
    color: #FF5E57;
}
.lesson-price[data-v-497362cb] {
    color: #FF5E57;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.empty[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.empty img[data-v-497362cb] {
    width: 120px;
    height: 74px;
}
.live-module[data-v-497362cb] {
    margin-bottom: 40px;
}
.live-list[data-v-497362cb] {
    /* height: 170px; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.live-item[data-v-497362cb] {
    width: 588px;
    height: 100%;
    /* margin-right: 20px; */
    padding: 20px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #FAFAFA;
    cursor: pointer;
    margin-bottom: 20px;
}
/* .live-item:last-child {
    margin-right: 0;
} */
.live-top[data-v-497362cb] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.live-top img[data-v-497362cb] {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 30px;
}
.live-top .title[data-v-497362cb] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
}
.live-bot[data-v-497362cb] {
    height: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
}
.live-bot .name[data-v-497362cb] {
    width: 80px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 30px;
}
.live-bot .live-sign[data-v-497362cb] {
    padding: 0 20px;
    height: 32px;
    font-size: 16px;
    border-radius: 16px;
    line-height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #EAECEF;
    color: #666;
}
.live-bot .live-sign img[data-v-497362cb] {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}
.union[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.union-module[data-v-497362cb] {
    margin-bottom: 30px;
}
.union[data-v-497362cb] {
    position: relative;
}
.union .union-item[data-v-497362cb] {
    height: 90px;
    width: 236px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    padding: 5px;
}
.union .union-item[data-v-497362cb]:nth-child(5n) {
    margin-right: 0;
}
.union .union-item img[data-v-497362cb] {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.union .union-l[data-v-497362cb], .union .union-r[data-v-497362cb] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.union .union-l[data-v-497362cb] {
    position: absolute;
    left: -36px;
}
.union .union-r[data-v-497362cb] {
    position: absolute;
    right: -36px;
}
.union .union-l img[data-v-497362cb], .union .union-r img[data-v-497362cb] {
    width: 100%;
    height: 100%;
}
.notice[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 40px;
    border: 1px solid #E9E9E9;
    padding: 5px;
    border-radius: 4px;
}
.notice .more[data-v-497362cb] {
  cursor: pointer;
  color: #888;
  font-size: 12px;
}
.notice .titleWrap[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 20px;
    padding: 0 10px;
}
.notice .title[data-v-497362cb] {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
}
.notice .title[data-v-497362cb]::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: #11A0F8;
}
.notice-content[data-v-497362cb] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 256px;
    border-radius: 6px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: #fff;
}
.notice-content[data-v-497362cb]::-webkit-scrollbar, .player-list[data-v-497362cb]::-webkit-scrollbar {
    width:3px;
}
.notice-content[data-v-497362cb]::-webkit-scrollbar-thumb, .player-list[data-v-497362cb]::-webkit-scrollbar-thumb{
      background-color:#aaa;
}
.notice .articleT[data-v-497362cb] {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 280px;
}
.notice-content li[data-v-497362cb] {
    cursor: pointer;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 0 10px;
}
.notice-content li[data-v-497362cb]:last-child {
    margin-bottom: 0;
}
.notice-content span[data-v-497362cb] {
    display: block;
    text-align: left;
    line-height: 12px;
    color: #666;
    font-size: 12px;
}
.notice-item-title[data-v-497362cb] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 5px;
}
.notice-item-title .icon[data-v-497362cb] {
    margin-right: 10px;
    background: #FF8684;
    color: #fff;
    width: 38px;
    border-radius: 4px;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.tem-user-wrap[data-v-497362cb] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
  /* .experts-wrap {
    height: 620px;
    background-image: url(../../assets/images/main_bottom.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position:bottom;

  } */
.experts-list[data-v-497362cb] {
    /* box-sizing: content-box; */
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.experts-item[data-v-497362cb] {
    -webkit-transition: all .3s;
    transition: all .3s;
    width: 175px;
    height: 250px;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    background: #FFFFFF;
    text-align: left;
    cursor: pointer;    
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.experts-item[data-v-497362cb]:nth-child(6n) {
    margin-right: 0;
}
.experts-item img[data-v-497362cb] {
    /* width: 175px; */
    height: 175px;
    -o-object-fit: cover;
       object-fit: cover;
    /* margin-bottom: 10px; */
    border-radius: 5px 5px 0px 0px;
}
.experts-title[data-v-497362cb] {
    /* margin-bottom: 25px; */
    margin-top: 10px;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000;
}
.experts-about[data-v-497362cb] {
    /* width: 155px; */
    height: 18px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 18px;
    text-align: center;
    font-size: 12px; 
    color: #767676;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
