
.page {
  width: 100%;
  background: #fff;
  padding-bottom: 30px;
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 30px;
  width: 100%;
  height: 66px;
  border-bottom: 1px solid #f3f3f3;
}
.header img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}
.header p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.info {
  padding: 32px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.info .img {
  width: 370px;
  height: 208px;
  border-radius: 4px;
  overflow: hidden;
  -ms-flex-negative: 0;
      flex-shrink: 0;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.info .text-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
}
.info .text-wrap .tit {
  height: 26px;
  font-size: 20px;
  color: #2c2b31;
  font-weight: bold;
  line-height: 26px;
  overflow: hidden;
  text-align: left;
}
.info .text-wrap .des {
  min-height: 115px;
  width: 100%;
  font-size: 14px;
  color: #727275;
  line-height: 25px;
  margin-top: 10px;
  text-align: left;
}
.info .text-wrap .button {
  width: 155px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  background-color: #0063b1;
  margin-top: 25px;
  cursor: pointer;
}
.info .text-wrap .go-btn-wrap {
  text-align: left;
  margin-top: 25px;
}
.detail {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px;
}
.detail .l {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 400px;
}
.detail .r {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 370px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
}
.detail .r .lec,
.detail .r .rec {
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin-bottom: 20px;
}
.detail .top {
  height: 64px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 24px;
}
.detail .top .line {
  background-color: #0063b1;
  width: 3px;
  height: 16px;
}
.detail .top .t {
  margin-left: 8px;
  color: #2c2b31;
  font-size: 18px;
}
.detail .l .course-des {
  border-top: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.detail .l .course-des .desc {
  padding: 0 28px 30px 36px;
  font-size: 14px;
  line-height: 25px;
  color: #434347;
  text-align: left;
  text-indent: 2em;
}
.detail .l .course-list {
  border-bottom: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.detail .l .course-list .course-list-items {
  padding: 0 20px;
}
.detail .l .course-list .course-list-items .chapter-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
  padding: 0 15px;
  overflow: hidden;
  margin-bottom: 10px;
  /* background-color: #f3f3f3; */
}
.detail .l .course-list .course-list-items .chapter-title p {
  color: #434347;
  font-weight: bold;
  font-size: 16px;
}
.detail .l .course-list .course-list-items .chapter-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 44px;
  padding: 0 15px;
  cursor: pointer;
}
.detail .l .course-list .course-list-items .chapter-item .t {
  font-size: 14px;
  font-weight: bold;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: #434347;
}
.detail .l .course-list .course-list-items .chapter-item .time {
  font-size: 12px;
  color: #a1a1a3;
}
.detail .r .lec .body {
  padding: 0 36px;
}
.detail .r .lec .body .lec-info {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.detail .r .lec .body .lec-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.detail .r .lec .body .lec-info .lec-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 10px;
}
.detail .r .lec .body .lec-info .lec-name .name {
  height: 26px;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}
.detail .r .lec .body .lec-info .lec-name .job {
  height: 20px;
  text-align: left;
  font-size: 12px;
  color: #999;
}
.detail .r .lec .body .des {
  font-size: 14px;
  line-height: 26px;
  text-align: left;
  margin: 10px 0;
}
.detail .r .rec .body {
  padding: 0 36px 30px;
}
.detail .r .rec .body .course-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 14px;
  width: 100%;
  height: 74px;
  cursor: pointer;
}
.detail .r .rec .body .course-item .img {
  width: 132px;
  height: 74px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  overflow: hidden;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.detail .r .rec .body .course-item .counrse-info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.detail .r .rec .body .course-item .counrse-info .t {
  font-size: 14px;
  color: #2c2b31;
}
.detail .r .rec .body .course-item .counrse-info .count {
  font-size: 12px;
  color: #a1a1a3;
}
p {
  margin: 0;
  text-align: left;
}
.text-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.unfold {
  display: block;
  z-index: 11;
  float: right;
  width: 40px;
  height: 21px;
}
.unfold p {
  margin: 0;
  line-height: 21px;
  color: #0063b1;
}
