
.bg[data-v-0f0963e9] {
    width: 100%;
    height: 560px;
    background: url(../img/productMatrixBg.734edec7.jpg) no-repeat center center;
    background-size: cover;
}
.inner[data-v-0f0963e9] {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 60px;
}
.inner p[data-v-0f0963e9] {
    width: 490px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 70px;
}
.btn[data-v-0f0963e9] {
    widows: 116px;
    height: 46px;
    border-radius: 10px;
    background: #37B979;
    font-size: 15px;
    color: #fff;
    border: none;
}
.menu-inner[data-v-0f0963e9] {
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
}
.menu-inner .title[data-v-0f0963e9] {
    font-size: 30px;
    line-height: 30px;
    color: #111D30;
    font-weight: bold;
    margin-bottom: 66px;
}
.menu[data-v-0f0963e9] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.active-border[data-v-0f0963e9] {
    border-color: #0066CC!important;
    color: #0066CC!important;
}
.menu li[data-v-0f0963e9] {
    width: 174px;
    height: 50px;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 31px;
    cursor: pointer;
    font-size: 20px;
    color: #999;
    line-height: 48px;
    font-weight: bold;
}
.menu li[data-v-0f0963e9]:nth-child(6n) {
    margin-right: 0;
}
.menu-content[data-v-0f0963e9] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.menu-content .left[data-v-0f0963e9] {
    -webkit-box-flex: 3;
        -ms-flex: 3;
            flex: 3;
    -webkit-box-shadow: 0 0 10px #ddd;
            box-shadow: 0 0 10px #ddd;
    border-radius: 10px;
    margin-right: 20px;
    overflow: hidden;
}
.menu-content .right[data-v-0f0963e9] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2;
    height: 460px;
    padding-left: 50px;
    background: url(../img/ppbg.5292e829.png) no-repeat;
    background-size: 100% 100%;
}
.menu-content .right h3[data-v-0f0963e9] {
    font-size: 30px;
    font-weight: bold;
    color: #111D30;
    line-height: 30px;
    margin-bottom: 30px;
}
.menu-content .right p[data-v-0f0963e9] {
    font-size: 14px;
    color: #999;
    line-height: 22px;
    text-align: left;
    margin-bottom: 50px;
}
.menu-content .right .btn[data-v-0f0963e9] {
    width: 116px;
    height: 46px;
    color: #fff;
    background: #0066CC;
}
.bg-title[data-v-0f0963e9] {
    font-size: 38px;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top, #fff, #eee, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
