
#myVideo[data-v-3d7de03a], #canvas[data-v-3d7de03a] {
    border-radius: 50%;
}
.face-wrap[data-v-3d7de03a] {
    height: 220px;
    margin-bottom: 20px;
    padding-top: 50px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
}
.face-txt[data-v-3d7de03a] {
    color: #bbb;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
}
.status-txt[data-v-3d7de03a] {
    text-align: center;
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    padding: 8px 20px;
    color: #fff;
    border-radius: 4px;
}
.photo-img[data-v-3d7de03a] {
    width: 220px;
    height: 220px;
    border-radius: 110px;
    margin: 0 auto;
    -o-object-fit: cover;
       object-fit: cover;
}
#myfile[data-v-3d7de03a] {
    display: none;
}
#file-btn[data-v-3d7de03a] {
    background: rgb(8, 128, 241);
    font-size: 14px;    
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border-radius: 4px;
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    display: block;
    line-height: 40px;
}
