
.archives[data-v-6783f064] {
    padding: 20px;
    min-height: 500px;
}
.title-wrap[data-v-6783f064] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.title[data-v-6783f064] {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 18px;
    font-size: 16px;
    margin-bottom: 15px;
}
.sub-t[data-v-6783f064] {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 8px;
    font-weight: bold;
    line-height: 16px;
    font-size: 14px;
    margin-bottom: 15px;
    margin-bottom: 40px;
}
.data-wrap[data-v-6783f064] {
    height: 140px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 50px;
}
.data-wrap li[data-v-6783f064] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border-right: 1px solid #ddd;
    cursor: pointer;
}
.data-wrap li span[data-v-6783f064] {
    display: block;
}
.data-wrap li .num[data-v-6783f064] {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 5px;
    line-height: 32px;
}
.data-wrap li .num-msg-wrap .num-msg[data-v-6783f064] {
    font-size: 14px;
    color: #555;
    font-weight: bold;
}
.data-wrap li .num-msg-wrap[data-v-6783f064] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 32px;
    height: 32px;
}
.data-wrap li .num-msg-wrap img[data-v-6783f064] {
    width: 32px;
    height: 32px;
}
.progress-wrap[data-v-6783f064] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 50px;
}
.progress-wrap .progress-item[data-v-6783f064] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.progress-wrap .progress-item .progress-item-content[data-v-6783f064] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 40px;
}
.progress-item-msg[data-v-6783f064] {
    font-weight: bold;
    font-size: 14px;
    color: #555;
    text-align: left;
    margin-left: 30px;
}
.progress-item-msg>div[data-v-6783f064] {
    padding: 5px 0;
}
.loading[data-v-6783f064] {
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
