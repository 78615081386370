
.bank-bg[data-v-28e48b32] {
    background: #F6F8FB;
}
.bg[data-v-28e48b32] {
    width: 100%;
    height: 150px;
    background: url(../img/cbankBg.a69be8f2.png) no-repeat;
    margin-bottom: 20px;
}
.inner[data-v-28e48b32] {
    width: 1220px;
    margin: 0 auto 20px;
}
.credit-wrap[data-v-28e48b32] {
    height: 270px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 44px;
}
.credit-item[data-v-28e48b32] {
    height: 270px;
    width: 700px;
    background: #fff;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 3px #ddd;
            box-shadow: 0 0 3px #ddd;
}
.credit-item-r[data-v-28e48b32] {
    height: 270px;
    width: 500px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 3px #ddd;
            box-shadow: 0 0 3px #ddd;
}
.credit-item .code[data-v-28e48b32] {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    right: 0;
}
.bank-card[data-v-28e48b32] {
    width: 206px;
    height: 107px;
}
.card-wrap[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.info-wrap[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.photo[data-v-28e48b32] {
    width: 76px;
    height: 76px;
    border-radius: 38px;
    margin-right: 15px;
}
.name-wrap[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 12px;
}
.name-wrap span[data-v-28e48b32] {
    font-size: 20px;
    color: #000;
    font-weight: bold;
    line-height: 20px;
}
.name-wrap img[data-v-28e48b32] {
    width: 26px;
    height: 26px;
}
.phone-msg[data-v-28e48b32] {
    font-size: 12px;
    color: #999;
    height: 30px;
    background: #F6F8FB;
    display: block;
    line-height: 30px;
    border-radius: 4px;
    padding: 0 10px;
}
.score[data-v-28e48b32] {
    font-size: 12px;
    color: #999;
    line-height: 12px;
}
.msg-ac[data-v-28e48b32] {
    color: #0066CC;
    font-size: 30px;
    font-weight: bold;
}
.score-wrap[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.score-wrap li[data-v-28e48b32] {
    height: 80px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-right: 1px solid #D0D7E1;
    padding-bottom: 20px;
}
.score-wrap li[data-v-28e48b32]:last-child {
    border-right: none;
}
.title-card[data-v-28e48b32] {
    font-size: 24px;
    color: #333;
    text-align: left;
    position: absolute;
    left: 20px;
    top: 10px;
}
.ser-wrap[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.ser-wrap li[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 45px;
    cursor: pointer;
}
.ser-wrap li img[data-v-28e48b32] {
    width: 76px;
    height: 76px;
    margin-bottom: 10px;
}
.ser-wrap li span[data-v-28e48b32] {
    font-size: 14px;
    color: #333;
    line-height: 14px;
}
.content[data-v-28e48b32] {
    background: #fff;
    border-radius: 10px;
}
.content .menu[data-v-28e48b32] {
    height: 50px;
    border-bottom: 2px solid #0066CC;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.content .menu li[data-v-28e48b32]:first-child {
    border-top-left-radius: 10px;
}
.content .menu li[data-v-28e48b32] {
    width: 116px;
    background: #fff;
    color: #333;
    font-size: 16px;
    height: 100%;
    line-height: 50px;
    border-right: 1px solid #D0D7E1;
    cursor: pointer;
}
.table[data-v-28e48b32] {
    padding: 0 25px 25px;
}
.tab-ac[data-v-28e48b32] {
    color: #fff!important;
    background: #0066CC!important;
}
.tab-item[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 35px;
}
.tab-item .tit[data-v-28e48b32] {
    text-align: left;
    margin-right: 15px;
    display: block;
}
.tab-wrap[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 25px;
}
.check-btn[data-v-28e48b32] {
    width: 74px;
    height: 36px;
    background: #0066CC;
    color: #fff;
    border-radius: 10px;
    border: none;
}
.tab-list[data-v-28e48b32] {
    margin-bottom: 25px;
}
.tab-list li[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 50px;
}
.tab-list li span[data-v-28e48b32] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.tab-list .tab-item-li[data-v-28e48b32] {
    color: #666;
}
.tab-list .tab-item-li span[data-v-28e48b32] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.tab-title[data-v-28e48b32] {
    -webkit-box-flex: 3!important;
        -ms-flex: 3!important;
            flex: 3!important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.page-bot[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.cert-wrap[data-v-28e48b32] {
    padding: 0 25px 25px;
}
.cert-list[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.cert-list li[data-v-28e48b32] {
    width: 272px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-right: 20px;
    margin-bottom: 30px;
}
.cert-list li img[data-v-28e48b32] {
    margin-bottom: 14px;
}
.cert-list li[data-v-28e48b32]:last-child {
    margin-right: 0;
}
.loading[data-v-28e48b32] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 600px;
}
