
.objcover[data-v-1b92c146] {
  -o-object-fit: cover;
     object-fit: cover;
}
button[data-v-1b92c146] {
  color: #333!important;
  border: 1px solid #fff!important;
}
.aIcon[data-v-1b92c146] {
  font-size: 18px;
  margin-right: 10px;
}
.pho[data-v-1b92c146] {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  border: 2px solid #fff;
}
.tel[data-v-1b92c146] {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  margin-right: 80px;
}
.classTime[data-v-1b92c146] {
  color: #fff;
  font-size: 20px;
}
.classTime span[data-v-1b92c146] {
  font-weight: bold;
}
.btn[data-v-1b92c146] {
  /* margin-right: 80px; */
  width: 100px;
  height: 40px;
}
.inner[data-v-1b92c146] {
  width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 50px;
}
.userInfo[data-v-1b92c146] {
  width: 100%;
  height: 180px;
  background: url(../img/personBg.6088fca0.png);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 20px;
}
.sidebar .item[data-v-1b92c146] {
  height: 36px;
  line-height: 36px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sidebar[data-v-1b92c146] {
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #eee;
  margin-right: 20px;
  min-height: 500px;
  padding:20px 0;
  border-radius: 4px;
  position: relative;
}
.content[data-v-1b92c146] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}
.personalWrap[data-v-1b92c146] {
  width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 0 auto 20px;
}
.personal[data-v-1b92c146] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 1024px) {
.userInfo[data-v-1b92c146] {
    height: auto;
    padding: 5px 0;
}
.inner[data-v-1b92c146] {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 10px 0;
}
.pho[data-v-1b92c146] {
    width: 70px;
    height: 70px;
    margin-right: 0;
    margin-bottom: 5px;
}
.tel[data-v-1b92c146] {
    margin-right: 0;
    font-size: 16px;
    margin-bottom: 5px;
}
.btn[data-v-1b92c146] {
    margin-right: 0;
    margin-bottom: 5px;
}
.classTime[data-v-1b92c146] {
    font-size: 16px;
}
.personalWrap[data-v-1b92c146] {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.sidebar[data-v-1b92c146] {
    margin-right: 0;
    width: 100%;
    min-height: auto;
    padding: 0;
    margin-bottom: 20px;
}
}
.tem-two-user-info[data-v-1b92c146] {
  width: 100%;
  height: 130px;
  background: url(../img/title-bg.cd4e80a0.png) no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}
.newbg[data-v-1b92c146]{
    background: url(../img/class_newbg.ecc6569c.jpg) no-repeat;
    background-size: cover;
}
.tem-two-user[data-v-1b92c146] {
  width: 200px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: -105px;
}
.tem-two-user-inner[data-v-1b92c146] {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  border-bottom: 1px solid #D3D3D3;
  color: #333;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tem-two-user-inner img[data-v-1b92c146] {
  width: 80px;
  height: 80px;
  border-radius: 2px;
  -o-object-fit: cover;
     object-fit: cover;
}
.tem-two-user-inner .tem-two-name[data-v-1b92c146] {
  font-size: 24px;
  margin-bottom: 10px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.tem-two-user-inner .tem-two-num[data-v-1b92c146] {
  font-size: 14px;
  margin-bottom: 10px;
}
.tem-two-user-info-inner[data-v-1b92c146] {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-left: 220px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-bottom: 25px;
}
.changeColor[data-v-1b92c146] {
  display: block;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(0,0,0,0.7);
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 54px;
  left: 0;
}
.pho-wrap[data-v-1b92c146] {
  width: 80px;
  height: 80px;
  position: relative;
  border: 1px solid #eee;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.up[data-v-1b92c146] {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
/* modal */
.do-some[data-v-1b92c146] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.txt-wrap[data-v-1b92c146] {
  padding: 20px 0;
}
.sync-txt[data-v-1b92c146] {
  font-weight: bold;
}
.sync-sub[data-v-1b92c146] {
  font-size: 12px;
  color: #888;
}
.item-wrap[data-v-1b92c146] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
