
.job[data-v-7a9f7164] {
    height: 100%;
    background: #F6F8FB;
}
.inner[data-v-7a9f7164] {
    width: 1200px;
    margin: 0 auto;
}
.title[data-v-7a9f7164] {
    font-size: 24px;
    color: #333;
    text-align: left;
    line-height: 24px;
    margin-bottom: 20px;
    padding-top: 40px;
}
.menu[data-v-7a9f7164] {
    height: 50px;
    border-bottom: 2px solid #0066CC;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.menu li[data-v-7a9f7164]:first-child {
    border-top-left-radius: 10px;
}
.menu li[data-v-7a9f7164] {
    width: 148px;
    background: #fff;
    color: #333;
    font-size: 15px;
    height: 100%;
    line-height: 50px;
    border-right: 1px solid #D0D7E1;
    cursor: pointer;
    font-weight: bold;
}
.tab-ac[data-v-7a9f7164] {
    color: #fff!important;
    background: #0066CC!important;
}
.top-content[data-v-7a9f7164] {
    background: #fff;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 30px 30px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.top-content li[data-v-7a9f7164] {
    width: 270px;
    height: 120px;
    margin-right: 20px;
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer;
}
.top-content li[data-v-7a9f7164]:nth-child(4n) {
    margin-right: 0;
}
.top-content li img[data-v-7a9f7164] {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.top-content li .t[data-v-7a9f7164] {
    color: #000;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-width: 190px;
}
.top-content li .st[data-v-7a9f7164] {
    color: #999;
    line-height: 12px;
    font-size: 12px;
}
.top-content li .sst[data-v-7a9f7164] {
    color: #333;
    font-size: 14px;
    line-height: 16px;
}
.top-content li .top[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.top-content li .bot[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.more-content[data-v-7a9f7164] {
    height: 180px;
    border-radius: 10px;
    background: #fff;
    padding: 30px 25px;
    margin-bottom: 20px;
}
.search[data-v-7a9f7164] {
    width: 960px;
    height: 50px;
    border: 1px solid #D0D7E1;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    border-right: none;
    margin-bottom: 20px;
}
.search .icon[data-v-7a9f7164] {
    width: 16px;
    height: 16px;
}
.search .one[data-v-7a9f7164] {
    width: 113px;
    border-right: 1px solid #D0D7E1;
}
.search input[data-v-7a9f7164] {
    border: none;
    height: 50px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-left: 10px;
}
.search .btn[data-v-7a9f7164] {
    height: 50px;
    width: 134px;
    background: #0066CC;
    color: #fff;
}
.menus-list[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
    padding-left: 30px;
}
.menus-list span[data-v-7a9f7164] {
    font-size: 12px;
    color: #999;
    line-height: 12px;
}
.menus-list li[data-v-7a9f7164] {
    font-size: 12px;
    color: #666;
    margin-right: 20px;
    cursor: pointer;
    line-height: 12px;
}
.menus-list ul[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.ac[data-v-7a9f7164] {
    color: #0066CC!important;
}
.job-list-wrap[data-v-7a9f7164] {
    border-radius: 10px;
    background: #fff;
    padding: 30px 25px;
    margin-bottom: 20px;
}
.job-list[data-v-7a9f7164] {
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    padding: 0 25px;
}
.job-list li[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 25px 0;
    border-bottom: 1px solid #D0D7E1;
    cursor: pointer;
}
.job-list li[data-v-7a9f7164]:last-child {
    border-bottom: 0;
}
.job-list .left h3[data-v-7a9f7164] {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    margin-bottom: 15px;
    text-align: left;
}
.job-list .left .pri[data-v-7a9f7164] {
    font-size: 18px;
    line-height: 18px;
    color: #F34026;
    display: inline-block;
    text-align: left;
}
.job-list .left .su[data-v-7a9f7164] {
    font-size: 12px;
    line-height: 12px;
    color: #666;
    display: inline-block;
}
.job-list .left .r[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.job-list .left .f[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.job-list .left .f span[data-v-7a9f7164]{
    font-size: 12px;
    line-height: 12px;
    color: #999;
    display: inline-block;
    width: 76px;
    height: 30px;
    background: #F6F8FB;
    line-height: 30px;
    margin-right: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.job-list .left .f span[data-v-7a9f7164]:last-child {
    margin-right: 0;
}
.job-list .right[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 550px;
}
.job-list .right img[data-v-7a9f7164] {
    width: 80px;
    height: 80px;
    margin-left: 20px;
}
.job-list .right .d[data-v-7a9f7164] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.job-list .right h3[data-v-7a9f7164] {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    margin-bottom: 15px;
    text-align: left;
    margin-bottom: 20px;
}
.job-list .right .su[data-v-7a9f7164] {
    font-size: 12px;
    line-height: 12px;
    color: #666;
    display: inline-block;
    margin-bottom: 20px;
}
.job-list .right .suu[data-v-7a9f7164] {
    font-size: 12px;
    color: #999;
    display: inline-block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
