
.exam-icon[data-v-6127f77c] {
  width: 72px;
  height: 63px;
}
.exam-item-wrap[data-v-6127f77c] {
  padding: 0 30px;
}
.exam-btn-wrap[data-v-6127f77c] {
  padding: 20px 0;
  text-align: center;
}
.exam-item[data-v-6127f77c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  min-height: 104px;
}
.exams ul li[data-v-6127f77c]{
    border: 1px solid #eee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;
    height: 160px;
}
.exams ul li h3[data-v-6127f77c] {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
}
.exams ul li .left[data-v-6127f77c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.exams ul li .left p[data-v-6127f77c] {
  margin-bottom: 0;
  font-size: 12px;
  color: #888;
  text-align: left;
  margin-bottom: 15px;
}
.exams ul li .left p span[data-v-6127f77c] {
  padding-right: 10px;
}
.exams ul li button[data-v-6127f77c] {
  border: none;
  font-size: 14px;
}
.exams ul li .pass_icon[data-v-6127f77c] {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  height: 24px;
  width: 80px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
}
.side-bar-wrap[data-v-6127f77c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.empty[data-v-6127f77c] {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-6127f77c] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-6127f77c] {
  font-size: 12px;
  color: #666;
}
.btn-txt-info[data-v-6127f77c] {
  font-size: 12px;
  color: #c00;
  line-height: 28px;
  padding: 0!important;
  display: inline-block;
}
.exam-start-wrap[data-v-6127f77c] {
  padding: 20px;
}
.exam-top[data-v-6127f77c] {
  background: #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}
.warn-icon[data-v-6127f77c] {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.warn-icon span[data-v-6127f77c] {
  color: #333;
  font-size: 14px;
}
.start-txt[data-v-6127f77c] {
  font-size: 15px;
  color: #333;
  line-height: 1;
  margin-bottom: 15px;
}
.start-do-wrap[data-v-6127f77c] {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
