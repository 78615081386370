
.payres img[data-v-8103a444] {
  width: 117px;
  height: 123px;
  margin-bottom: 30px;
}
.payres h3[data-v-8103a444] {
  padding-top: 80px;
  margin-bottom: 60px;
  font-weight: bold;
  font-size: 20px;
}
.payres button[data-v-8103a444] {
  margin:0 10px;
  width: 98px!important;
  height: 36px;
}
.payres button[data-v-8103a444]:last-child {
  color: #0071F5;
  background: #fff;
}
