
.empty[data-v-2f3655b6] {
  padding: 120px 0;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-2f3655b6] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-2f3655b6] {
  font-size: 12px;
  color: #666;
}
button[data-v-2f3655b6] {
  font-size: 14px;
}
.loadding[data-v-2f3655b6] {
  height: 500px;
}
.myclass .tab[data-v-2f3655b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #0071f5;
  margin-bottom: 0;
}
.myclass .tab .item[data-v-2f3655b6] {
  cursor: pointer;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #eee;
}
.itemWrap[data-v-2f3655b6] {
  margin-bottom: 20px;
}
.tcItem[data-v-2f3655b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}
.tcItem .describe[data-v-2f3655b6] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    color: #666;
}
.info[data-v-2f3655b6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.top[data-v-2f3655b6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.title[data-v-2f3655b6] {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  cursor: pointer;
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.tcItem .describe[data-v-2f3655b6] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.img[data-v-2f3655b6] {
  width: 320px;
  height: 200px;
  margin-right: 20px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.ico[data-v-2f3655b6] {
  width: 60px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 12px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.img img[data-v-2f3655b6] {
  -o-object-fit: cover;
     object-fit: cover;
}
.class-sign[data-v-2f3655b6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.class-sign li[data-v-2f3655b6] {
    margin-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.class-sign li img[data-v-2f3655b6] {
    width: 16px;
    height: 14px;
    margin-right: 5px;
}
.class-sign li span[data-v-2f3655b6] {
    color: #000;
    font-size: 12px;
}
.title-do-wrap[data-v-2f3655b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.do-some[data-v-2f3655b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.txt-wrap[data-v-2f3655b6] {
  padding: 20px 0;
}
.sync-txt[data-v-2f3655b6] {
  font-weight: bold;
}
