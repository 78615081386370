
.modal[data-v-fca6007c] {
    width: 190px;
    z-index: 99999999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    position: relative;
}
.code-img[data-v-fca6007c] {
    padding: 10px;
    border-radius: 6px;
    background: #fff;
    width: 190px;
    border: 1px solid #ddd;
    position: absolute;
    right: 0;
    top: 42px;
}
.btn-wrap[data-v-fca6007c] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.btn-wrap img[data-v-fca6007c] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
