
div[data-v-11617e16]{
    line-height: 1.2;
}
p span[data-v-11617e16] {
    padding: 0px;
    margin: 0px;
}
.el-button[data-v-11617e16]{
    width: 160px;
    height: 30px;
}
.flex[data-v-11617e16] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.flex_column[data-v-11617e16] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.rssb_main[data-v-11617e16]{
    width: 1200px;
    margin: 30px auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.banner img[data-v-11617e16]{
    width:100%;
    height: 400px;
    display: inline-block;
    cursor: pointer;
}
.rssb_list[data-v-11617e16]{
    padding-top: 20px;
}
.rssb_list_item[data-v-11617e16]{
    width: 100%;
    position: relative;
}
.absolute[data-v-11617e16] {
    position: absolute;
}
.rssb-list_item_main[data-v-11617e16] {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    border: 1px solid rgba(225,229,237,0.7500);
    border-radius: 10px;
    margin-bottom: 10px;
}
.rssb_list_item[data-v-11617e16] :last-child {
    margin-bottom: 5px;
}
.rssb_list_item_date[data-v-11617e16]{
  text-align: center;
  /* padding-left: 60px; */
  /* width: 124px; */
  /* height: 16px; */
  line-height: 16px;
}
.common_block span[data-v-11617e16]{
   display: block;
}

/* .common_blocks span{
   display: inline-block;
} */
.round[data-v-11617e16]{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #24B324;
    border-radius: 50%;
    /* margin-left: 20px; */
    margin-top: 16px;
}
.common_font[data-v-11617e16]{
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    /* line-height: 24px; */
}
.common_fonts[data-v-11617e16]{
    font-weight: 400;
    color: #000000;
    font-size: 18px;
}
.rssb_date[data-v-11617e16] {
    font-weight: 400;
    color: #000000;
    font-size: 16px; 
    /* margin-left: 20px; */
    margin-top: 22px;
    margin-bottom: 22px;
}
.itemA_data[data-v-11617e16] {
    font-weight: bold;
    height: 16px;
    font-size: 18px;
    display: block;
    margin-top: 13px;
    margin-left:20px;
    color: #24B324;
}
.head_img[data-v-11617e16]{
    width:50px;
    height:50px;
    margin-right: 20px;
    margin-left: 76px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    /* margin-top: -4px; */
}
.text_title[data-v-11617e16]{
    display: block;
    width: 594px;
    /* height: 67px; */
    font-family: FZLTCHK--GBK1-0;
    font-weight: bold;
    margin-top: 4px;
    /* display: inline-block; */
}
.text_name[data-v-11617e16] {
    font-size: 14px;
    margin-top:8px;
    display: inline-block;
}
.btn[data-v-11617e16]{
    width: 120px;
    height: 30px;
    background: rgba(0,39,118,0);
    border: 1px solid rgba(0,0,0,0.7500);
    border-radius: 15px;
    padding: 0;
    color: #fff;
    position: absolute;
    right:30px;
    top:35px;
    background: #002776;
    font-size: 16px;
    font-weight: bold;
}
.el-button--primary.is-plain[data-v-11617e16]:focus, .el-button--primary.is-plain[data-v-11617e16]:hover{
    background: #0040bf;
}
.el-carousel__item h3[data-v-11617e16] {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item[data-v-11617e16]:nth-child(2n) {
     background-color: #99a9bf;
}
.el-carousel__item[data-v-11617e16]:nth-child(2n+1) {
     background-color: #d3dce6;
}
[data-v-11617e16].el-carousel__arrow i {
        font-size: 29px !important;
}
.list_item[data-v-11617e16]{
      position: relative;
      margin-bottom: 25px;
}
.list_container .list_item[data-v-11617e16]:last-child {
    margin-bottom: 0px;
}
