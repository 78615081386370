
.inner[data-v-6f60e869] {
    width: 1200px;
    margin: 0 auto;
}
.top-wrap[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 420px;
    padding-top: 25px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    margin-bottom: 25px;
}
.banner[data-v-6f60e869] {
    width: 750px;
    height: 100%;
    margin-right: 20px;
    border-radius: 2px;
    overflow: hidden;
}
.news[data-v-6f60e869] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 100%;
}
.title-bar[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 18px;
}
.title-bar img[data-v-6f60e869] {
    width: 6px;
    height: 22px;
    margin-right: 5px;
}
.title-wrap[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.title-wrap span[data-v-6f60e869] {
    font-size: 22px;
    line-height: 22px;
    color: #333;
    font-weight: bold;
}
.title-bar .more[data-v-6f60e869] {
    font-size: 14px;
    line-height: 22px;
    color: #999;
    cursor: pointer;
}
.carouselImgWrap img[data-v-6f60e869] {
    height: 420px;
    -o-object-fit: cover;
       object-fit: cover;
}
.news[data-v-6f60e869] {
    height: 420px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.news-wrap[data-v-6f60e869] {
    height: 380px;
}
.news .date[data-v-6f60e869] {
    width: 80px;
    height: 80px;
    background: #F5F5F5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
}
.news .date-day[data-v-6f60e869] {
    font-size: 36px;
    color: #1F1E1C;
    font-weight: bold;
    line-height: 40px;
}
.news .dates[data-v-6f60e869] {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.news li[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.news li[data-v-6f60e869]:last-child {
    margin-bottom: 0;
}
.news .news-item[data-v-6f60e869] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    border-bottom: 1px solid #E6E6E6;
    height: 80px;
}
.news-item .news-title[data-v-6f60e869] {
    width: 340px;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    margin-bottom: 0;
    cursor: pointer;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.news-item .news-title[data-v-6f60e869]:hover {
    color: #0880F1;
}
.news-item .news-info[data-v-6f60e869] {
    width: 340px;
    font-size: 14px;
    color: #666;
    line-height: 20px;
    height: 20px;
    margin-bottom: 0;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.flower[data-v-6f60e869] {
    height: 130px;
    border: 1px solid #E6E6E6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 40px;
}
.flower-item[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flower-item>span[data-v-6f60e869] {
    font-size: 14px;
    color: #2A2928;
    font-weight: bold;
    line-height: 14px;
}
.flower-item img[data-v-6f60e869]{
    width: 54px;
    height: 54px;
    margin-bottom: 10px;
}
.flower .flower-sign[data-v-6f60e869] {
    width: 17px;
    height: 21px;
}
.classes-list[data-v-6f60e869] {
    padding-bottom: 40px;
}
.classes-item[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    margin-left: -20px;
    padding: 20px;
    -webkit-transition: all .3s;
    transition: all .3s;
    border: 1px solid #eee;
}
.classes-item:hover .classes-go[data-v-6f60e869] {
    background: #0880F1;
}
.classes-item[data-v-6f60e869]:last-child {
    margin-bottom: 0;
}
.classes-item .img .classes-sign-ing[data-v-6f60e869] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAAAlCAYAAAE7AYf8AAAAAXNSR0IArs4c6QAAA/tJREFUeAHtWstrE0EYn2+zzTtbrKBFRKwWKmgFwYNihVLoQfBQxKseRKwHFS96sc3LF+Kl+DhUqP4BngQfR8FTPehBinrxINqKFyVJm8S02XFm08+ma2P2lelm3YXkm5nMN9/3+347k3mBcrPcW6lUnhKTj6wuLoYIpX0m9YhkVgHri1eEcCb/Ec2bkbKlwACMWMJYTClPLClySBBO56gZbFgX4tnC7iVVncGCVkvLEK04Vk53glCD3EmhBqPZ/ClZDUk/SYk+tBIiszqqSg5Z7lFmjWn1KX1pqSdaMsaU2EuzSyiH3FFHDb44GW0K3lGDgz0yKaUU7aO3zMo1W44ZnB6N6W2syu95TDp4gbyq1EZmQwRIVa0Nr7OFv4fZT+/zv1jz4JjBvol5Q+46FlJD1jjEcDo/ZbSy3Xps5P4g/O/JrtOm9OXgduEUmnLQRmUA+F4ei3z2LEA2rnXz+LgaIB+U65+Z8/H6bMO0BCSDP7oaIDqJQHd2GXO3mOpMo64xDawtSCIgq+Yi6dxd1PXsKMrn9xykKxnE6NuR4UxOmwp4FiChJBbNLuwTu6SwQ4kFXaHLFwv+2VLx7KvJ/gOvexbcQlIZ9yw4NvekHgUHz3lndS24LrZUHtgWsDSggCyfcTW4/s0SGe5dWeU/OhYxDLQ0Fp11NThEcmkgSPZvkYjRiTQQmENd176W6GA8CCQe0qaMWPRPyQaSa1jBleDuHQ0TMI4HsWhSJeotLHAluL3dAXYCgy6alJQkUMOV4L7kVPKNbSZyyT+z+VoanW4mY9n5fl7H8slPMwPr+TsbVKZLaeWgK5mzGxhK6AHehifBcWC9d2jIs+C+/shPeBYcI+8sm9+AkJNE/qqIfjy7EyY6kKLtSSCd83K3Ex1PYfbYX/m7Yipx3ydPWMgdNCRRbUm+suZ1sG2/qRZGAGCqlFRecwt+z2thnJ1vGspd3YkL2K5PHkaiDaQEcHFuFIroqk8eRsKEHOwJaPfIMkOhhlodDkeW7aC9ZZOUyXqDDpuob/r/ScfYTbyhHSvnAa9Ox0h+fPWdFLvRAKl2blDfjk9efTQMpvV7rZcPh8izEzHCe2RLHoDJYjL2Rt+2T54+Ii7MAyUb13LLJ2+tqKxRxi948eFwPR62BX08lpkf1tv2ydNHxKX5Kq0+0Lvm723qI9IgH2WTkvISIctX+onCJpraDf7l+okgIYVKLRNgU8MQ2/4oLjZozGqxBFfKSeUGqvs9DyPRRHIikDhetZ44nkfieLrKDo8dJ443rJKr8duFTTzJH5+8Whza5JtK1QX6Z63nk9cmtKGbbPIyEs3kjvC8Tx5GpY0kpaBNXnzy2og0dJX1vq3hbCH5G1E1+USgzpwcAAAAAElFTkSuQmCC) no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 111px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img .classes-sign-end[data-v-6f60e869] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAlCAYAAAGi+V5JAAAAAXNSR0IArs4c6QAAAgJJREFUaAXtma9OA0EQxrmjooI0QdaBq+MZqnA8AIaU4BpIUfSPIIg2TSrapCkOJAYFDwAOg0MTFArZIC5cuOObkiGXGmYv23SSzpq9S76bfvPbmcveNuh2u+maYIQCzUyyRGGwnGSCIDgUZZ2m6bVISCDFyXhfmGUFvBCzETo8L2BpzoRikUz3oiDb2CtDBKx4Ddhqtd68BqRVW7GAeMlVfaf8oL9TRP3pIPKN0OGn/5ei8caqDbbb7RO1BkHvhRirNVgsFquqDUZR9KraILZSm6oNkrler3entgbJICjued/SUGBfA538rpogEh2qNlguly9VG6zVapFqg1TLag2iQe5VG8QrZqTaYKfTeVRtkMzRUFuDv/bMIHNwn9EkAT2ldon7/f6BaoMg2NBucEe1QTJHQ20NkrnBYLCt2mAcxw3vZymUucfxpXrL7zHRhYTCjutWdQkuJGuPQcMwHBnAnEBRfc/NZvPJAOYEyN8jhZzPr/RjqL4PnPzeEASrwBylAICzr2EDmA/ed6lUGvOjVoFMQjjj3XdVr9c/WW4AmYRwRvsOs1IDmKUhuAbArazMAGZpCK6TJJmdc7DUADIJ+byL/+UqLDeATMJt/qtCA+gGjtVHk8lkg24MICNxmLGVWZ9Op8cG0AHavJQPze08cJ6Mwz2Os/athR2AzUtRhac/CjJ3JtjpeuYAAAAASUVORK5CYII=) no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 88px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img[data-v-6f60e869] {
    width: 350px;
    height: 200px;
    margin-right: 20px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
}
.classes-item .img img[data-v-6f60e869] {
    -o-object-fit: cover;
       object-fit: cover;
}
.classes-title[data-v-6f60e869] {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    line-height: 30px;
    height: 30px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
    margin-top: 10px;
}
.classes-about[data-v-6f60e869] {
    min-height: 72px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.classes-sign[data-v-6f60e869] {
    color: #FF5E57;
}
.classes-price[data-v-6f60e869] {
    color: #FF5E57;
    font-size: 20px;
    font-weight: bold;
}
.classes-info[data-v-6f60e869] {
    height: 200px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.classes-do-wrap[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.classes-go[data-v-6f60e869] {
    width: 86px;
    height: 34px;
    background: #F2F2F2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-transition: all .2s;
    transition: all .2s;
    overflow: hidden;
    border-radius: 2px;
}
.classes-item:hover .classes-go .classes-go-img[data-v-6f60e869] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAKCAYAAAFJfdcxAAAAAXNSR0IArs4c6QAAAMtJREFUOBFjYMAG/v//Px2IUQCGOkaQNIYoLgGCioEKmGGamWAMLLQ4UKEwSJwY+8WxGIBfCGg6O8itQCCErBKfk5DVwdmMjIw/gZgRKMAGMe+/KFyS7gygC24A8VmKLYYadIEaBl0DGnYJZBAxUUnIwquEFFBFHujiRCA+BcSCVDGQEkOAjpAC4ltADALngBglzVJiNtl6gY6QBOLrQAwCF4FYBNkwUFy7AQUMkQXpwAYVBQVADMqQV4DYCZg3X4McEwDkWADxQINrAEvQi65+fmZHAAAAAElFTkSuQmCC) no-repeat;
}
.classes-go .classes-go-img[data-v-6f60e869] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAKCAYAAAFJfdcxAAAAAXNSR0IArs4c6QAAAQ1JREFUOBFjYMACGNPT06f///8/A4scQogxLS3tP4JLgEVQ8apVq5hhZsAZMAEYLS4uLmlqasp2+vTp7wTtZ2JiEmeE6SSWzs3NZf/58+cPbm5u4f7+/ncwfSQbBNOYlZUl8efPn+fs7OxikydPfg0Tpy8NjNEbwCg4S7GtIIOA+ALFBgFdcw2IL4EMIhiVRNh2lewYIsJwuBKg1xOBOSOTmZnZffr06e/hEmgMJjQ+TbisrKw7GRkZBf7+/fsO6LBzhYWFQtgsokvIwCwGxqkkkL0PiDWAGBS/zrNmzXoDpMEAFNduQFcbwgToQQOjTBxoTwEQA61mvMLGxuYEyowgxwQABSzo4QgCdlwDAP2bUzjom0pHAAAAAElFTkSuQmCC) no-repeat;
    width: 35px;
    height: 10px;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.module-item[data-v-6f60e869] {
    margin-bottom: 40px;
}
.lesson-item[data-v-6f60e869]:hover, .classes-item[data-v-6f60e869]:hover {
    -webkit-box-shadow: 0 0 8px #ccc;
            box-shadow: 0 0 8px #ccc;
}
.lesson-list[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.lesson-item[data-v-6f60e869] {
    -webkit-transition: all .3s;
    transition: all .3s;
    width: 270px;
    height: 330px;
    margin-right: 20px;
    background: #FAFAFA;
    text-align: left;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    padding: 15px;
}
.lesson-item[data-v-6f60e869]:nth-child(3n) {
    margin-right: 0;
}
.lesson-item img[data-v-6f60e869] {
    height: 160px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
}
.lesson-title[data-v-6f60e869] {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-about[data-v-6f60e869] {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 14px;
    color: #666;
}
.lesson-name[data-v-6f60e869] {
    min-height: 18px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-name span[data-v-6f60e869] {
    height: 18px;
    line-height: 18px;
}
.lesson-price-info[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.lesson-msg-wrap[data-v-6f60e869] {
    background: #FAFAFA;
}
.lesson-price-sign[data-v-6f60e869] {
    color: #FF5E57;
}
.lesson-price[data-v-6f60e869] {
    color: #FF5E57;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.empty[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.empty img[data-v-6f60e869] {
    width: 120px;
    height: 74px;
}
.live-module[data-v-6f60e869] {
    margin-bottom: 40px;
}
.live-list[data-v-6f60e869] {
    /* height: 170px; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.live-item[data-v-6f60e869] {
    width: 590px;
    height: 100%;
    margin-right: 20px;
    padding: 20px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #FAFAFA;
    cursor: pointer;
}
.live-item[data-v-6f60e869]:last-child {
    margin-right: 0;
}
.live-top[data-v-6f60e869] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.live-top img[data-v-6f60e869] {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 30px;
}
.live-top .title[data-v-6f60e869] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
}
.live-bot[data-v-6f60e869] {
    height: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
}
.live-bot .name[data-v-6f60e869] {
    width: 80px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 30px;
}
.live-bot .live-sign[data-v-6f60e869] {
    padding: 0 20px;
    height: 32px;
    font-size: 16px;
    border-radius: 16px;
    line-height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #EAECEF;
    color: #666;
}
.live-bot .live-sign img[data-v-6f60e869] {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}
/*
    1+x css
 */
.notice-1x[data-v-6f60e869] {
    -webkit-box-shadow: 0 5px 5px #eee;
            box-shadow: 0 5px 5px #eee;
    margin-bottom: 60px;
}
.notice-1x-inner[data-v-6f60e869] {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.notice-1x-icon[data-v-6f60e869] {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}
.news-wrap1x[data-v-6f60e869] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.news-wrap1x .date[data-v-6f60e869] {
    width: 68px;
    height: 68px;
    background: #F6F8FB;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    border-radius: 4px;
}
.news-wrap1x .date-day[data-v-6f60e869] {
    font-size: 36px;
    color: #1F1E1C;
    font-weight: bold;
    line-height: 40px;
}
.news-wrap1x .dates[data-v-6f60e869] {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.news-wrap1x li[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-left: 10px;
}
.news-wrap1x li[data-v-6f60e869]:last-child {
    margin-bottom: 0;
}
.news-wrap1x .news-item[data-v-6f60e869] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 68px;
}
.news-wrap1x .news-item .news-title[data-v-6f60e869] {
    color: #333;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
    text-align: left;
}
.news-wrap1x .news-item .news-title[data-v-6f60e869]:hover {
    color: #0880F1;
}
.news-wrap1x .news-item .news-info[data-v-6f60e869] {
    font-size: 14px;
    color: #888;
    line-height: 20px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    text-align: left;
}
.one1x[data-v-6f60e869] {
 width: 1200px;
 margin: 0 auto 56px;
}
.one1x .lv[data-v-6f60e869] {
    width: 100%;
    height: 210px;
    background: url(../img/lv.1fb302b4.png);
    margin-bottom: 20px;
    background-size: cover;
}
.one1x .la[data-v-6f60e869] {
    width: 100%;
    height: 210px;
    background: url(../img/la.94ac4a88.png);
    margin-bottom: 20px;
    background-size: cover;
}
.one1x .hu[data-v-6f60e869] {
    width: 100%;
    height: 210px;
    background: url(../img/hu.c18841a9.png);
    background-size: cover;
}
.one1x .lv[data-v-6f60e869], .one1x .la[data-v-6f60e869], .one1x .hu[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-right: 15px;
}
.one1x .lv img[data-v-6f60e869], .one1x .la img[data-v-6f60e869], .one1x .hu img[data-v-6f60e869] {
    width: 224px;
    height: 166px;
    margin-right: 25px;
}
.one1x .one1xitem[data-v-6f60e869] {
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.one1x .one1xitem[data-v-6f60e869]:hover {
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
    -webkit-box-shadow: 10px 0 10px #bbb;
            box-shadow: 10px 0 10px #bbb;
}
.two1x[data-v-6f60e869] {
    background: #f6f8fb;
    padding: 60px 0 56px;
}
.two1x-menu[data-v-6f60e869] {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 24px;
}
.two1x-menu li[data-v-6f60e869] {
    width: 110px;
    height: 36px;
    border-radius: 10px;
    line-height: 36px;
    background: #E5E9F0;
    color: #555;
    margin: 0 8px;
    cursor: pointer;
}
.two1x-menu-ac[data-v-6f60e869] {
    background: #0066cc!important;
    color: #fff!important;
}
.two1x-list[data-v-6f60e869] {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.two1x-list li[data-v-6f60e869] {
    width: 288px;
    margin-right: 16px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 22px;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.two1x-list li[data-v-6f60e869]:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    -webkit-box-shadow: 0 5px 5px #ddd;
            box-shadow: 0 5px 5px #ddd;
}
.two1x-list li[data-v-6f60e869]:nth-child(4n) {
    margin-right: 0;
}
.two1x-list li img[data-v-6f60e869] {
    width: 288px;
    height: 154px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 22px;
}
.two1x-list .two1x-price[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px;
}
.thr1x[data-v-6f60e869] {
    background: url(../img/bankbg.88c702d6.png);
    background-size: cover;
    height: 586px;
    padding-top: 60px;
}
.thr1x ul[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.thr1x ul li[data-v-6f60e869] {
    width: 288px;
    height: 322px;
    background: #fff;
    border-radius: 10px;
    margin: 0 12px;
    padding: 40px 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
}.thr1x ul li[data-v-6f60e869]:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.thr1x ul li img[data-v-6f60e869] {
    width: 76px;
    height: 76px;
    margin-bottom: 10px;
}
.thr1x ul li h3[data-v-6f60e869] {
    color: #111D30;
    font-size: 18px;
    margin-bottom: 18px;
    line-height: 18px;
    font-weight: bold;
}
.thr1x ul li p[data-v-6f60e869] {
    color: #666;
    text-align: left;
    font-size: 12px;
}
.title1x[data-v-6f60e869] {
    font-size: 30px;line-height: 30px;font-weight: bold;
}
.subt1x[data-v-6f60e869] {
    font-size: 16px; line-height: 22px; margin-bottom: 40px
}
.fou1x[data-v-6f60e869] {
    padding: 60px 0;
    background: #f6f8fb;
}
.fou1x ul[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.fou1x ul li[data-v-6f60e869] {
    margin: 0 10px;
}
.fou1x ul li img[data-v-6f60e869] {
    width: auto;
    height: auto;
}
.fiv1x[data-v-6f60e869] {
    background: #f6f8fb;
    padding-bottom: 60px;
}
.fiv1x-menu[data-v-6f60e869] {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 24px;
}
.fiv1x-menu li[data-v-6f60e869] {
    width: 110px;
    height: 36px;
    border-radius: 10px;
    line-height: 36px;
    background: #E5E9F0;
    color: #555;
    margin: 0 8px;
    cursor: pointer;
}
.fiv1x-menu-ac[data-v-6f60e869] {
    background: #0066cc!important;
    color: #fff!important;
}
.fiv1xlist[data-v-6f60e869] {
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.fiv1xlist li[data-v-6f60e869]{
    width: 380px;
    background: #fff;
    border-radius: 10px;
    height: 126px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 10px 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.fiv1xlist li[data-v-6f60e869]:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    -webkit-box-shadow: 0 5px 5px #ddd;
            box-shadow: 0 5px 5px #ddd;
}
.fiv1xlist li[data-v-6f60e869]:nth-child(3n) {
    margin-right: 0;
}
.fiv1xlist li .title[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 20px;
}
.fiv1xlist li .info[data-v-6f60e869] {
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: #999;
}
.fiv1xlist li .bot[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 14px;
}
.six1x[data-v-6f60e869] {
    background: url(../img/six1x.d887e839.png) no-repeat center center;
    background-size: cover;
    height: 700px;
    padding-top: 60px;
}
.sev1x[data-v-6f60e869] {
    width: 1200px;
    height: 730px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.sev1x .tab[data-v-6f60e869] {
    width: 380px;
    margin-right: 90px;
}
.sev1x .tabImg[data-v-6f60e869] {
    width: 450px;
    height: 800px;
    margin-bottom: -150px;
    z-index: 9999;
    padding-left: 30px;
    position: relative;
    overflow: hidden;
}
.sev1x .tabImg .img-item[data-v-6f60e869] {
    position: absolute;
    left: 96px;
    top: 65px;
    width: 291px;
    height: 675px;
    z-index: -999;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-transition:0.5s linear;
    transition:0.5s linear;
}
.sev1x .tab .menu[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.sev1x .tab .menu li[data-v-6f60e869] {
    width: 80px;
    height: 80px;
    margin-right: 70px;
    margin-bottom: 70px;
    -webkit-box-shadow: 0 0 20px #eee;
            box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    cursor: pointer;
}
.sev1x .tab .menu li img[data-v-6f60e869] {
    margin-bottom: 10px;
    border-radius: 10px;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.sev1x .tab .menu li[data-v-6f60e869]:nth-child(3n) {
    margin-right: 0;
}
.news-menus[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.news-menus li[data-v-6f60e869] {
    font-size: 20px;
    line-height: 22px;
    margin-right: 10px;
    margin-bottom: 0;
    cursor: pointer;
    font-weight: bold;
}
.content-wrap[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.content-left[data-v-6f60e869] {
    width: 850px;
}
.content-right[data-v-6f60e869] {
    width: 330px;
}
.right-module[data-v-6f60e869] {
    border: 1px solid #eee;
    padding: 15px;
    margin-bottom: 30px;
}
#school-cert-in .ant-form-item[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 650px;
}
#school-cert-in .ant-input[data-v-6f60e869] {
    height: 40px;
}
.thr-sub-title[data-v-6f60e869] {
    line-height: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: left;
    padding: 0 20px;
    font-size: 14px;
    margin-bottom: 0;
    color: #999;
}
.close-btn[data-v-6f60e869] {
    text-align: center;
}
.coll-tab[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}
.coll-tab li[data-v-6f60e869] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid #fff;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.coll-tab-ac[data-v-6f60e869] {
    border-color: rgb(8, 128, 241)!important;
    background: rgb(8, 128, 241)!important;
    color: #fff;
    border-radius: 4px;
}
.school-list-wrap li[data-v-6f60e869] {
    display: block;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding: 18px 20px;
    font-weight: bold;
    color: #555;
    cursor: pointer;
    line-height: 16px;
    height: 32px;
}
.school-list-wrap li[data-v-6f60e869]::before{
    content: "★";
    color: rgb(8, 128, 241);
}
.school-list-wrap li[data-v-6f60e869]:hover {
    color: rgb(8, 128, 241)!important;
}
.comments .info[data-v-6f60e869] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}
.comments .info img[data-v-6f60e869] {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 10px;
}
.comments .timer[data-v-6f60e869] {
    color: #888;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
}
.comments .txt[data-v-6f60e869] {
    text-align: left;
}
.menus-li-1x[data-v-6f60e869] {
    border-bottom: 3px solid #fff;
}
