
.title-bg[data-v-0d387c11] {
    height: 130px;
    background: url(../img/title-bg.cd4e80a0.png) no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 30px;
}
.newbg[data-v-0d387c11]{
    background: url(../img/class_newbg.ecc6569c.jpg) no-repeat;
    background-size: cover;
}
.title-inner[data-v-0d387c11] {
  width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title-wrap[data-v-0d387c11] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.title-inner img[data-v-0d387c11] {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.title-inner h3[data-v-0d387c11] {
  font-size: 30px;
  margin-bottom: 0;
  background: -webkit-gradient(linear, left top, right top, from(#DCFBFE), to(#6EA2DF));
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  -webkit-background-clip: text;
  color: transparent;
}
.school-content[data-v-0d387c11] {
    width: 1200px;
    margin: 0 auto 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    min-height: 500px;
}
.item[data-v-0d387c11] {
    width: 350px;
    -webkit-box-shadow: 0 0 5px #ddd;
            box-shadow: 0 0 5px #ddd;
    min-height: 500px;
    border-radius: 6px;
}
.item li[data-v-0d387c11] {
    text-align: left;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 20px;
    cursor: pointer;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.item li[data-v-0d387c11]::before{
    content: "★"; 
    color: rgb(8, 128, 241);
}
.item li[data-v-0d387c11]:hover {
    color: rgb(8, 128, 241);
    font-weight: bold;
}
.item p[data-v-0d387c11] {
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 18px;
    line-height: 50px;
    border-radius: 6px;
    margin: 0 auto 20px;
}
.mid[data-v-0d387c11] {
    height: 120px;
    -webkit-box-shadow: 0 0 5px #ddd;
            box-shadow: 0 0 5px #ddd;
    margin-bottom: 30px;
}
.inner[data-v-0d387c11] {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
#school-cert-in .ant-form-item[data-v-0d387c11] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 650px;
}
#school-cert-in .ant-input[data-v-0d387c11] {
    height: 40px;
}
.close-btn[data-v-0d387c11] {
    text-align: center;
}
.empty[data-v-0d387c11] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.empty img[data-v-0d387c11] {
    width: 120px;
    height: 74px;
}
