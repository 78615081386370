
.classes-bg[data-v-f7d580ec] {
    background: url(../img/class-bg.5f71341c.png) no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.newbg[data-v-f7d580ec]{
    background: url(../img/class_newbg.ecc6569c.jpg) no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.inner[data-v-f7d580ec] {
    width: 1200px;
    margin: 0 auto;
}
.top[data-v-f7d580ec] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.title-wrap[data-v-f7d580ec] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 200px;
}
.title-wrap img[data-v-f7d580ec] {
    width: 54px;
    height: 54px;
    margin-right: 15px;
}
.title-wrap h3[data-v-f7d580ec] {
    font-size: 30px;
    margin-bottom: 0;
    background: -webkit-gradient(linear, left top, right top, from(#DCFBFE), to(#6EA2DF));
    background: linear-gradient(to right, #DCFBFE, #6EA2DF);
    -webkit-background-clip: text;
    color: transparent;
}
.search[data-v-f7d580ec] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 2px;
    overflow: hidden;
}
.search-msg[data-v-f7d580ec] {
    width: 400px;
    height: 50px;
    border-radius: 0;
}
.search-btn[data-v-f7d580ec] {
    width: 50px;
    height: 50px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC2UlEQVRoQ+2ZW0hUURSGv6PWIFghMiKVWRBi+pIGZVRmBQkFZYFGPVjaZE/1EFhBICIRdMGHekqtAYkuE3SDgkystMAuo/TgBQlEURAvRCWZ14nN6UQNKTP7nIOn2APnZdh7sb71/2uftWe0wLnYAP/BR1MgDlNRKeIwQVCKKEVsqoCylk2FlQ6rFJEunU0blSJ/LWzEPEjeoT8J6RCToC8b6Yf+Fuh8oj/TE5brYp0iyTthaznErpg9yU9dUF8KnY8thTEPokVAdimsOwaaBoPt8OEGdNXDl14IBGBhIizfDOmHwJ2if/fmCrwoh8C0JUDmQbaUQeZxmBqHujPQ4p0lOQ0yimDbWYhyQdNleF7mABBhp701uudv50FPY2hJJWVB/h2InA/3CiyxmbwiorGLm/SeeFoCzddCgzBWZXgg5wKInqnMNH0AyIOk7IY9Xhhog+tZEl7X4HAjxKfC/ULoeBheIYJWy4PsugppefDsNLyvlEtizRHYfh5a78Kjo3Ixfu6SByl+C3EroWoDDLXLJeFOBc8rGP4IlWvlYpgGOdEDrhi4uAQmR+WSiIqGkj4YG4GKZXIxzIN0g2sBXFoKE9/kkvgF8hUqkuRimAYxrFW9EQbb5JJwrwLP6zm2ltHstafAXyUH4ohmN45fMZIIVQjzB0sx2hQ1OOD4/f2FWHsS/NXhqeKYF6JI2xhRpsbAtx+6X4YGk7QJ8n0OGVGMlI2hcfK7Pp77xagyg82EndILHTg0CpjgMX6oA5q90N0An3t1qEVijM+G1QUgTipjjE9cD1ok+PbB6HBoas6wSv7NHhxQ5mJ1sA4WZ8BAK9zMNQVjHYgAC/eqGx0HBx5AfJppGGtBZMxhEczcgwh4C2CcARIM0/cOanLC0tc5IAaMuAKPj8Ct3H8YJKzU/1zsLEUUCOqvNxMmsGer6hF76iofVSkiXzt7dipF7KmrfFSliHzt7Nn5A8ebZlwZAX9GAAAAAElFTkSuQmCC) no-repeat;
    border: none;
    border-radius: 0;
}
.classes-content[data-v-f7d580ec] {
    padding: 30px 0 40px;
    width: 1200px;
    margin: 0 auto;
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.classes-list[data-v-f7d580ec] {
    padding-bottom: 40px;
    max-width: 1200px;
}
.classes-item[data-v-f7d580ec] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    padding: 20px;
    -webkit-transition: all .5s;
    transition: all .5s;
}
.classes-item:hover .classes-go[data-v-f7d580ec] {
    background: #0880F1;
}
.classes-item[data-v-f7d580ec]:last-child {
    margin-bottom: 0;
}
.classes-item .img .classes-sign-ing[data-v-f7d580ec] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAAAlCAYAAAE7AYf8AAAAAXNSR0IArs4c6QAAA/tJREFUeAHtWstrE0EYn2+zzTtbrKBFRKwWKmgFwYNihVLoQfBQxKseRKwHFS96sc3LF+Kl+DhUqP4BngQfR8FTPehBinrxINqKFyVJm8S02XFm08+ma2P2lelm3YXkm5nMN9/3+347k3mBcrPcW6lUnhKTj6wuLoYIpX0m9YhkVgHri1eEcCb/Ec2bkbKlwACMWMJYTClPLClySBBO56gZbFgX4tnC7iVVncGCVkvLEK04Vk53glCD3EmhBqPZ/ClZDUk/SYk+tBIiszqqSg5Z7lFmjWn1KX1pqSdaMsaU2EuzSyiH3FFHDb44GW0K3lGDgz0yKaUU7aO3zMo1W44ZnB6N6W2syu95TDp4gbyq1EZmQwRIVa0Nr7OFv4fZT+/zv1jz4JjBvol5Q+46FlJD1jjEcDo/ZbSy3Xps5P4g/O/JrtOm9OXgduEUmnLQRmUA+F4ei3z2LEA2rnXz+LgaIB+U65+Z8/H6bMO0BCSDP7oaIDqJQHd2GXO3mOpMo64xDawtSCIgq+Yi6dxd1PXsKMrn9xykKxnE6NuR4UxOmwp4FiChJBbNLuwTu6SwQ4kFXaHLFwv+2VLx7KvJ/gOvexbcQlIZ9yw4NvekHgUHz3lndS24LrZUHtgWsDSggCyfcTW4/s0SGe5dWeU/OhYxDLQ0Fp11NThEcmkgSPZvkYjRiTQQmENd176W6GA8CCQe0qaMWPRPyQaSa1jBleDuHQ0TMI4HsWhSJeotLHAluL3dAXYCgy6alJQkUMOV4L7kVPKNbSZyyT+z+VoanW4mY9n5fl7H8slPMwPr+TsbVKZLaeWgK5mzGxhK6AHehifBcWC9d2jIs+C+/shPeBYcI+8sm9+AkJNE/qqIfjy7EyY6kKLtSSCd83K3Ex1PYfbYX/m7Yipx3ydPWMgdNCRRbUm+suZ1sG2/qRZGAGCqlFRecwt+z2thnJ1vGspd3YkL2K5PHkaiDaQEcHFuFIroqk8eRsKEHOwJaPfIMkOhhlodDkeW7aC9ZZOUyXqDDpuob/r/ScfYTbyhHSvnAa9Ox0h+fPWdFLvRAKl2blDfjk9efTQMpvV7rZcPh8izEzHCe2RLHoDJYjL2Rt+2T54+Ii7MAyUb13LLJ2+tqKxRxi948eFwPR62BX08lpkf1tv2ydNHxKX5Kq0+0Lvm723qI9IgH2WTkvISIctX+onCJpraDf7l+okgIYVKLRNgU8MQ2/4oLjZozGqxBFfKSeUGqvs9DyPRRHIikDhetZ44nkfieLrKDo8dJ443rJKr8duFTTzJH5+8Whza5JtK1QX6Z63nk9cmtKGbbPIyEs3kjvC8Tx5GpY0kpaBNXnzy2og0dJX1vq3hbCH5G1E1+USgzpwcAAAAAElFTkSuQmCC) no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 111px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img .classes-sign-end[data-v-f7d580ec] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAlCAYAAAGi+V5JAAAAAXNSR0IArs4c6QAAAgJJREFUaAXtma9OA0EQxrmjooI0QdaBq+MZqnA8AIaU4BpIUfSPIIg2TSrapCkOJAYFDwAOg0MTFArZIC5cuOObkiGXGmYv23SSzpq9S76bfvPbmcveNuh2u+maYIQCzUyyRGGwnGSCIDgUZZ2m6bVISCDFyXhfmGUFvBCzETo8L2BpzoRikUz3oiDb2CtDBKx4Ddhqtd68BqRVW7GAeMlVfaf8oL9TRP3pIPKN0OGn/5ei8caqDbbb7RO1BkHvhRirNVgsFquqDUZR9KraILZSm6oNkrler3entgbJICjued/SUGBfA538rpogEh2qNlguly9VG6zVapFqg1TLag2iQe5VG8QrZqTaYKfTeVRtkMzRUFuDv/bMIHNwn9EkAT2ldon7/f6BaoMg2NBucEe1QTJHQ20NkrnBYLCt2mAcxw3vZymUucfxpXrL7zHRhYTCjutWdQkuJGuPQcMwHBnAnEBRfc/NZvPJAOYEyN8jhZzPr/RjqL4PnPzeEASrwBylAICzr2EDmA/ed6lUGvOjVoFMQjjj3XdVr9c/WW4AmYRwRvsOs1IDmKUhuAbArazMAGZpCK6TJJmdc7DUADIJ+byL/+UqLDeATMJt/qtCA+gGjtVHk8lkg24MICNxmLGVWZ9Op8cG0AHavJQPze08cJ6Mwz2Os/athR2AzUtRhac/CjJ3JtjpeuYAAAAASUVORK5CYII=) no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 88px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img[data-v-f7d580ec] {
    width: 430px;
    height: 240px;
    margin-right: 20px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
}
.classes-item .img img[data-v-f7d580ec] {
    -o-object-fit: cover;
       object-fit: cover;
}
.classes-title[data-v-f7d580ec] {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    line-height: 30px;
    height: 30px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
    margin-top: 10px;
}
.classes-about[data-v-f7d580ec] {
    min-height: 72px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.classes-sign[data-v-f7d580ec] {
    color: #FF5E57;
}
.classes-price[data-v-f7d580ec] {
    color: #FF5E57;
    font-size: 20px;
    font-weight: bold;
}
.classes-info[data-v-f7d580ec] {
    height: 240px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.classes-do-wrap[data-v-f7d580ec] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.classes-go[data-v-f7d580ec] {
    width: 86px;
    height: 34px;
    background: #F2F2F2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-transition: all .5s;
    transition: all .5s;
    overflow: hidden;
    border-radius: 2px;
}
.classes-item[data-v-f7d580ec]:hover {
    -webkit-box-shadow: 0 0 8px #ccc;
            box-shadow: 0 0 8px #ccc;
}
.classes-item:hover .classes-go .classes-go-img[data-v-f7d580ec] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAKCAYAAAFJfdcxAAAAAXNSR0IArs4c6QAAAMtJREFUOBFjYMAG/v//Px2IUQCGOkaQNIYoLgGCioEKmGGamWAMLLQ4UKEwSJwY+8WxGIBfCGg6O8itQCCErBKfk5DVwdmMjIw/gZgRKMAGMe+/KFyS7gygC24A8VmKLYYadIEaBl0DGnYJZBAxUUnIwquEFFBFHujiRCA+BcSCVDGQEkOAjpAC4ltADALngBglzVJiNtl6gY6QBOLrQAwCF4FYBNkwUFy7AQUMkQXpwAYVBQVADMqQV4DYCZg3X4McEwDkWADxQINrAEvQi65+fmZHAAAAAElFTkSuQmCC) no-repeat;
}
.classes-go .classes-go-img[data-v-f7d580ec] {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAKCAYAAAFJfdcxAAAAAXNSR0IArs4c6QAAAQ1JREFUOBFjYMACGNPT06f///8/A4scQogxLS3tP4JLgEVQ8apVq5hhZsAZMAEYLS4uLmlqasp2+vTp7wTtZ2JiEmeE6SSWzs3NZf/58+cPbm5u4f7+/ncwfSQbBNOYlZUl8efPn+fs7OxikydPfg0Tpy8NjNEbwCg4S7GtIIOA+ALFBgFdcw2IL4EMIhiVRNh2lewYIsJwuBKg1xOBOSOTmZnZffr06e/hEmgMJjQ+TbisrKw7GRkZBf7+/fsO6LBzhYWFQtgsokvIwCwGxqkkkL0PiDWAGBS/zrNmzXoDpMEAFNduQFcbwgToQQOjTBxoTwEQA61mvMLGxuYEyowgxwQABSzo4QgCdlwDAP2bUzjom0pHAAAAAElFTkSuQmCC) no-repeat;
    width: 35px;
    height: 10px;
    -webkit-transition: all .5s;
    transition: all .5s;
}
.bot[data-v-f7d580ec] {
    -webkit-box-shadow: 0 3px 5px #ccc;
            box-shadow: 0 3px 5px #ccc;
}
.bot .first-menus[data-v-f7d580ec] {
    min-height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-transition: all 1s;
    transition: all 1s;
    padding-top: 18px;
}
.bot .first-menus li[data-v-f7d580ec] {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-right: 30px;
    margin-bottom: 18px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-bottom: 2px;
    border-bottom: 2px solid #fff;
}
.second-menus[data-v-f7d580ec] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-transition: all 1s;
    transition: all 1s;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}
.second-menus li[data-v-f7d580ec] {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.third-menus[data-v-f7d580ec] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-transition: 1s all;
    transition: 1s all;
    margin-bottom: 20px;
}
.third-menus li[data-v-f7d580ec] {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.loadding[data-v-f7d580ec] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.ac-bg[data-v-f7d580ec] {
    color: #0880F1!important;
    background: rgba(194,224,252, .5)!important;
    border-radius: 4px;
    font-weight: bold;
}
.ac[data-v-f7d580ec] {
    color: #0880F1!important;
    font-weight: bold;
}
.no[data-v-f7d580ec] {
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.no img[data-v-f7d580ec] {
    width: 100px;
    height: 100px;
}
.no p[data-v-f7d580ec] {
    color: #bbb;
}
.menu-more[data-v-f7d580ec] {
    color: #666;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.menu-more img[data-v-f7d580ec] {
    width: 28px;
    height: 26px;
    padding: 5px;
    cursor: pointer;
}
