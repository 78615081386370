
.empty[data-v-5f6f8db8] {
  padding: 120px 0;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-5f6f8db8] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-5f6f8db8] {
  font-size: 12px;
  color: #666;
}
button[data-v-5f6f8db8] {
  font-size: 14px;
}
.loadding[data-v-5f6f8db8] {
  height: 300px;
}
.mycourse .tab[data-v-5f6f8db8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #0071f5;
  margin-bottom: 0;
}
.mycourse .tab .item[data-v-5f6f8db8] {
  cursor: pointer;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #eee;
}
.active[data-v-5f6f8db8] {
  background: #0071f5;
  color: #fff;
}
.mycourse .tcItem[data-v-5f6f8db8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}
.mycourse .tcItem .describe[data-v-5f6f8db8] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: 30px;
    color: #666;
}
.mycourse .img[data-v-5f6f8db8] {
  width: 330px;
  height: 200px;
  margin-right: 20px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.mycourse .itemWrap[data-v-5f6f8db8] {
  margin-bottom: 20px;
}
.mycourse .img img[data-v-5f6f8db8] {
  -o-object-fit: cover;
     object-fit: cover;
}
.mycourse .info[data-v-5f6f8db8] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.mycourse .top[data-v-5f6f8db8] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 40px;
}
.mycourse .title[data-v-5f6f8db8] {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  cursor: pointer;
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.progress[data-v-5f6f8db8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.progress span[data-v-5f6f8db8] {
  min-width: 80px;
}
.do-some[data-v-5f6f8db8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.txt-wrap[data-v-5f6f8db8] {
  padding: 20px 0;
}
.sync-txt[data-v-5f6f8db8] {
  font-weight: bold;
}
@media screen and (max-width: 1024px) {
.mycourse .tab .item[data-v-5f6f8db8] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.mycourse .tcItem[data-v-5f6f8db8] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
}
.mycourse .img[data-v-5f6f8db8] {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    height: 230px;
}
.mycourse .tcItem .describe[data-v-5f6f8db8] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
}
@media screen and (min-width:768px) and (max-width:1024px){
.mycourse .img[data-v-5f6f8db8] {
    height: 420px;
}
}
