
.examination[data-v-22af088a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: #fff;
}
.inner[data-v-22af088a] {
    width: 1200px;
    margin: 0 auto;
    background: #f9f9f9;
}
.subject-top[data-v-22af088a] {
    height: 150px;
    border: 1px solid #ddd;
    border-bottom: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 10px 0 20px;
    position: relative;
}
.top-title[data-v-22af088a] {
  text-align: left;
}
.top-title h3[data-v-22af088a] {
  color: #333;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.top-title p[data-v-22af088a] {
  margin-bottom: 0;
  font-size: 14px;
  color: #888;
}
.top-time h3[data-v-22af088a] {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #555;
}
.top-time span[data-v-22af088a] {
  display: block;
  font-size: 22px;
  color: #c00;
  line-height: 28px;
}
.top-score[data-v-22af088a] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.top-score h3[data-v-22af088a] {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: bold;
  margin-right: 10px;
  color: #555;
}
.top-score span[data-v-22af088a] {
  font-size: 48px;
  color: #c00;
  display: block;
}
.subject-content[data-v-22af088a] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 10px 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #ddd;
}
.subject[data-v-22af088a] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 20px;
}
.subject_active[data-v-22af088a]{
    overflow: auto;
}
.subject[data-v-22af088a]::-webkit-scrollbar {
  width: 0;
}
.subject[data-v-22af088a]::-webkit-scrollbar-thumb {
  background: #ccc;
}
.subject-child_active[data-v-22af088a]{
    height: 0;
}
.subject-child[data-v-22af088a] {
    width: 100%;
}
.subject-number[data-v-22af088a] {
    width: 315px;
    border-left: 1px solid #ddd;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    padding-left: 10px;
    height: 100%;
}
.subject-number .number-title[data-v-22af088a] {
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
}
.unit-item[data-v-22af088a] {
    margin-bottom: 30px;
}
.unit-item h3[data-v-22af088a] {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}
.unit-item .unit[data-v-22af088a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.unit-item .unit a[data-v-22af088a] {
    width: 35px;
    height: 28px;
    border: 1px solid #777;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    color: #555;
    line-height: 28px;
    text-align: center;
}
.selector[data-v-22af088a] {
  background: rgb(8, 128, 241)!important;
  color: #fff!important;
  /* border: none!important; */
}
.selector-suc[data-v-22af088a] {
  background: #52c41a;
  color: #fff!important;
  /* border: none!important; */
}
.selector-err[data-v-22af088a] {
  background: #c00;
  color: #fff!important;
  /* border: none!important; */
}
.errColor[data-v-22af088a] {
  background: #ff4d4f;
  color: #fff!important;
  border: none!important;
}
.sucColor[data-v-22af088a] {
  background: #52c41a;
  color: #fff!important;
  border: none!important;
}
.question h3[data-v-22af088a] {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 20px;
  text-align: left;
}
.qes-item[data-v-22af088a] {
  padding-left: 30px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.qes-item .score-item[data-v-22af088a] {
  color: #888;
}
.qes-title[data-v-22af088a] {
  font-size: 14px;
  color: #555;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}
.answers p[data-v-22af088a] {
  font-size: 14px;
  color: #555;
  padding: 8px;
  margin: 5px 0;
  text-align: left;
  cursor: pointer;
  max-width: 400px;
  border-radius: 6px;
  line-height: 18px;
  min-width: 120px;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.answers p[data-v-22af088a]:hover {
  background: #ddd;
}
.answers-wrap[data-v-22af088a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
}
.module-content[data-v-22af088a] {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.module-content h3[data-v-22af088a] {
  color: #555;
  font-weight: bold;
}
.module-content .msg-wrap[data-v-22af088a] {
  margin-bottom: 30px;
}
.module-content .msg-wrap p[data-v-22af088a]{
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}
.answer-content[data-v-22af088a] {
  margin-right: 20px;
  background: #eee;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
  padding-left: 10px;
}
.info_text[data-v-22af088a]{
  padding-top: 10px;
}
.info_text span[data-v-22af088a]{
   font-size: 12px;
   padding-right: 30px;
   color: #888;
   overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  display: inline-block;
}
.texts[data-v-22af088a]{
   font-size: 12px;
   color: #888;
}
