
.study_report[data-v-6cb15c07] {
    background: url(../img/report_bg.3b217a11.png) no-repeat;
    background-size: 100% 443px;
    position: relative;
}
.inner[data-v-6cb15c07] {
    width: 1200px;
    margin: 0 auto;
}
.top[data-v-6cb15c07] {
    height: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 70px;
}
.top h3[data-v-6cb15c07] {
    font-size: 30px;
    font-weight: bold;
    border-right: 1px solid #fff;
    color: #fff;
    padding-right: 30px;
    margin: 0 30px 0 0;
}
.top img[data-v-6cb15c07] {
    width: 183px;
    height: 60px;
}
.content[data-v-6cb15c07] {
    position: absolute;
    left: 70px;
    top: 150px;
    width: 1060px;
    background: #fff;
    padding: 40px 30px;
    border: 1px solid #ddd;
    border-radius: 6px;
}
.people-info[data-v-6cb15c07] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
}
.people-info .avatar[data-v-6cb15c07] {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-right: 20px;
}
.people-info .avatar-wrap[data-v-6cb15c07] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.people-info .name h3[data-v-6cb15c07] {
    margin-bottom: 10px;
    font-size: 20px;
    text-align: left;
}
.people-info .name span[data-v-6cb15c07] {
    color: #666;
}
.study-learn-wrap[data-v-6cb15c07] {
    padding-right: 50px;
}
.study-learn-wrap span[data-v-6cb15c07] {
    display: block;
    text-align: left;
}
.study-learn-wrap .learn-txt[data-v-6cb15c07] {
    color: #666;
}
.study-learn-wrap .learn-number[data-v-6cb15c07] {
    font-size: 44px;
    font-weight: bold;
    color: #2c75bb;
}
table[data-v-6cb15c07] {
    width: 100%;
    border-collapse:collapse;
    border-spacing:1;
    border-spacing:0;
    margin-bottom: 50px;
}
thead tr[data-v-6cb15c07] {
    background: #ddd;
    height: 40px;
}thead tr th[data-v-6cb15c07] {
    text-align: left;
    padding-left: 10px;
}
tbody td[data-v-6cb15c07] {
    text-align: left;
    padding-left: 10px;
    height: 40px;
}
.status[data-v-6cb15c07] {
    text-align: left;
}
.suc[data-v-6cb15c07] {
    color: #52c41a;
}
.err[data-v-6cb15c07] {
    color: #c00;
}
.progress-wrap[data-v-6cb15c07] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
}
.bar[data-v-6cb15c07] {
    background: #52c41a;
    height: 20px;
    margin-right: 10px;
}
.title[data-v-6cb15c07] {
    /* border-left: 4px solid rgb(8, 128, 241); */
    text-align: left;
    /* padding-left: 10px; */
    font-weight: bold;
    line-height: 18px;
    font-size: 16px;
    height: 18px;
    margin-bottom: 20px;
}
.lattice[data-v-6cb15c07] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.lattice li[data-v-6cb15c07] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 120px;
    border-right: 1px solid #eee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 1px solid #eee;
    padding-left: 20px;
}
.lattice li[data-v-6cb15c07]:first-child {
    border-left: 1px solid #eee;
}
.lattice-number[data-v-6cb15c07] {
    font-size: 30px;
    font-weight: bold;
    color: #333;
}
.down-btn[data-v-6cb15c07] {
    color: #555;
    font-size: 12px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    position: absolute!important;
    right: 70px;
    top: 20px;
}
.item[data-v-6cb15c07] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 20px;
    margin-bottom: 20px;
}
.item .item-bar[data-v-6cb15c07] {
    width: 4px;
    height: 18px;
    border-left: 4px solid rgb(8, 128, 241);
    margin-bottom: -16px;
}
.item h3[data-v-6cb15c07] {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    color: 333;
}
