
.link-detail[data-v-1066f3a8] {
    width: 50%;
}
.detail-wrap[data-v-1066f3a8] {
    background: #f9f9f9;
    padding: 20px;
    width: 100%;
    border-radius: 6px;
}
.detail-item[data-v-1066f3a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}
.detail-item .user img[data-v-1066f3a8] {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-bottom: 8px;
}
.detail-item .user span[data-v-1066f3a8] {
    font-size: 12px;
    line-height: 12px;
}
.detail-item .user[data-v-1066f3a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.detail-item .content[data-v-1066f3a8] {
    background: #eee;
    min-height: 60px;
    -webkit-box-flex:1;
        -ms-flex:1;
            flex:1;
    border-radius: 6px;
    padding:10px;
}
.detail-item .content p[data-v-1066f3a8] {
    text-align: left;
    line-height: 22px;
    font-size: 14px;
}
.detail-item .content span[data-v-1066f3a8] {
    font-size: 12px;
    color: #666;
    text-align: left;
    display: block;
}
.more[data-v-1066f3a8] {
    font-size: 12px;
    color: #666;
    cursor: pointer;
}
