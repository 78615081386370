
.teacher-item[data-v-b8bfca5e] {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}
.teacher-item img[data-v-b8bfca5e] {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    margin-right: 15px;
}
.teacher-item .detail[data-v-b8bfca5e] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 15px;
}
.teacher-item .text-info[data-v-b8bfca5e] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.teacher-item .text-info h3[data-v-b8bfca5e] {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}
.teacher-item .text-info p[data-v-b8bfca5e] {
    font-size: 14px;
    color: #666;
    text-align: left;
}
.teacher-item .infos[data-v-b8bfca5e] {
    width: 50%;
    text-align: left;
    font-size: 14px;
}
.empty[data-v-b8bfca5e] {
  padding: 50px 0;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-b8bfca5e] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-b8bfca5e] {
  font-size: 12px;
  color: #666;
}
