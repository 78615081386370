
.guide[data-v-5799d324] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.guide img[data-v-5799d324] {
    -o-object-fit: cover;
       object-fit: cover;
}
