
/*
认证模块
*/
.idcard-content[data-v-4c18e074] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px;
}
.idcard-status[data-v-4c18e074] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 15px;
}
.idcard-status img[data-v-4c18e074] {
  width: 20px;
  height: 20px;
}
.idcard-status span[data-v-4c18e074] {
  font-size: 14px;
  margin-right: 5px;
}
button[data-v-4c18e074] {
  color: #fff!important;
  border: none;
}
.resetBtn[data-v-4c18e074] {
  background: #fff;
  color: #333!important;
  border: 1px solid #bbb;
}
.resetBtn[data-v-4c18e074]:hover {
  color: #333;
}
.setuser[data-v-4c18e074] {
    padding: 20px;
    border: 1px solid #eee;
    min-height: 500px;
}
.bindBtn span[data-v-4c18e074] {
    cursor: pointer;
    margin-right: 20px;
}
.setuser .content[data-v-4c18e074] {
    padding: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
}
.content .form[data-v-4c18e074] {
    width: 320px;
}
.setuser .title[data-v-4c18e074] {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
}
.setuser label[data-v-4c18e074] {
    min-width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
}
.setuser .item[data-v-4c18e074] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 15px;
}
.setuser .item input[data-v-4c18e074] {
    width: 240px;
    text-align: left;
    height: 40px;
}
.setuser .do[data-v-4c18e074] {
    margin-bottom: 20px;
}
.setuser .do button[data-v-4c18e074]:first-child {
    margin-right: 20px;
}
.wechat[data-v-4c18e074] {
    width: 40px;
    height: 40px;
    margin: 0 auto;
}
.annotation[data-v-4c18e074] {
    color: #aaa;
    white-space: nowrap;
    margin: 0;
}
.select-wrap[data-v-4c18e074] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media screen and (max-width: 1024px) {
.setuser[data-v-4c18e074] {
      width: 100%;
      min-height: 400px;
}
.setuser .content[data-v-4c18e074] {
      width: auto;
      margin: 0;
}
.setuser .title[data-v-4c18e074] {
      font-size: 16px;
      margin-bottom: 20px;
}
}
.photo-up-wrap[data-v-4c18e074] {
    width: 142px!important;
    height: 200px!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #eee;
    font-size: 50px;
    color: #999;
    cursor: pointer;
    position: relative;
}
.hoverBg[data-v-4c18e074] {
    color: #fff;
    font-size: 14px;
    height: 30px;
    width: 100%;
    line-height: 30px;
    background: rgba(0, 0, 0, .7);
    position: absolute;
    left: 0;
    bottom: 0;
}
.con-top[data-v-4c18e074] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.positive[data-v-4c18e074], .other-side[data-v-4c18e074] {
    border: 1px dashed #ccc;
    border-radius: 6px;
    width: 315px;
    height: 190px!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
}
.positive[data-v-4c18e074]:hover, .other-side[data-v-4c18e074]:hover {
     border-color: rgb(8, 128, 241);
}
.card-bg[data-v-4c18e074] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.card-bg img[data-v-4c18e074] {
    width: 74px;
    height: 48px;
    margin-bottom: 10px;
}
.positive span[data-v-4c18e074], .other-side span[data-v-4c18e074] {
     display: block;
     font-size: 14px;
     line-height: 16px;
     color: rgb(8, 128, 241);
}
.card-wrap[data-v-4c18e074] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 30px;
}
.card-data[data-v-4c18e074] {
    width: 100%;
    height: 100%;
}
.top-msg[data-v-4c18e074] {
    background: #f7f7f7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 10px;
    margin-bottom: 30px;
}
.top-msg img[data-v-4c18e074] {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.top-msg p[data-v-4c18e074] {
    font-size: 14px;
    color: #666;
}
.card-do[data-v-4c18e074] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.card-do button[data-v-4c18e074] {
    width: 130px;
    height: 40px;
    margin: 0 10px;
}
