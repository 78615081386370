
.cert-list[data-v-23dda9f5] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.cert-item[data-v-23dda9f5] {
        width: 310px;
        margin-right: 25px;
        border: 1px solid #eee;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-radius: 4px;
        min-height: 420px;
}
.cert-item[data-v-23dda9f5]:nth-child(3n) {
        margin-right: 0;
}
.conditions[data-v-23dda9f5] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 5px 10px;
}
.conditions span[data-v-23dda9f5]:last-child {
        color: #666;
}
.conditions-wrap[data-v-23dda9f5] {
        margin-bottom: 20px;
        min-height: 93px;
}
.up-btn[data-v-23dda9f5] {
        font-size: 14px;
        background: #1D75FA;
        border-radius: 4px;
        color: #fff!important;
        cursor: pointer;
        padding: 4px;
        line-height: 14px;
}
.see-btn[data-v-23dda9f5] {
        display: inline-block;
        width: 74px;
        height: 32px;
        background: #1D75FA;
        line-height: 32px;
        color: #fff;
        border-radius: 4px;
}
.empty[data-v-23dda9f5] {
        padding: 50px 0;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.empty img[data-v-23dda9f5] {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span[data-v-23dda9f5] {
  font-size: 12px;
  color: #666;
}
