
.job[data-v-4414c154] {
    height: 224px;
    background: #F6F8FB;
}
.job .inner[data-v-4414c154] {
    padding: 50px 0;
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.icons[data-v-4414c154] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.title-wrap[data-v-4414c154] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.icons li[data-v-4414c154] {
    background: #E5E9F0;
    width: 86px;
    height: 36px;
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 36px;
    margin-right: 10px;
    border-radius: 6px;
}
.content .inner[data-v-4414c154] {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.title-group[data-v-4414c154] {
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 28px;
}
.title-group .icon[data-v-4414c154] {
    width: 6px;
    height: 18px;
    display: block;
    background: #0066CC;
    margin-right: 10px;
}
.title-group .txt[data-v-4414c154] {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}
.text-line[data-v-4414c154] {
    font-size: 14px;
    color: #333;
    text-align: left;
    margin-bottom: 44px;
    padding-left: 10px;
}
.text-line p[data-v-4414c154] {
    margin-bottom: 0;
    font-size: 14px;
    color: #333;
    line-height: 30px;
}
.left[data-v-4414c154] {
    width: 840px;
}
.right[data-v-4414c154] {
    width: 320px;
}
.r-top[data-v-4414c154] {
    height: 270px;
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    margin-bottom: 27px;
    padding: 25px;
}
.r-bot[data-v-4414c154] {
    height: 490px;
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    padding: 25px;
}
.r-top .title[data-v-4414c154], .r-bot .title[data-v-4414c154] {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 30px;
    text-align: left;
}
.comp-icon[data-v-4414c154] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.icon-wrap[data-v-4414c154] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.title-pic[data-v-4414c154] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 25px;
}
.title-pic img[data-v-4414c154] {
    width: 60px;
    height: 60px;
    margin-right: 16px;
}
.line-item li[data-v-4414c154] {
    padding: 24px 0;
    border-bottom: 1px solid #D0D7E1;
    cursor: pointer;
}
.line-item li[data-v-4414c154]:first-child {
    padding-top: 0;
}
.line-item li[data-v-4414c154]:last-child {
    border-bottom: none;
}
.line-item h3[data-v-4414c154]{
    font-size: 14px;
    color: #000;
    margin-bottom: 12px;
    line-height: 14px;
    text-align: left;
}
.line-item .sub[data-v-4414c154] {
    font-size: 12px;
    line-height: 12px;
    color: #999;
}
.line-item .sub-wrap[data-v-4414c154] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
