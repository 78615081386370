
.listModule[data-v-36c2f7eb] {
  width: 100%;
  height: 100%;
  padding: 0 0 20px;
}
.listModule .list[data-v-36c2f7eb] {
  width: 1200px;
  margin: 0 auto 20px;
}
.listModule .title[data-v-36c2f7eb] {
  font-weight: bold;
  font-size: 22px;
  color: #333;
  text-align: left;
  line-height: 30px;
  height: 30px;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
overflow: hidden;
margin-bottom: 30px;
}
.listModule .describe[data-v-36c2f7eb] {
  text-align: left;
  color: #666;
  font-size: 14px;
  line-height: 30px;
  min-height: 120px;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 4;
overflow: hidden;
margin-bottom: 0;
}
.listModule .date[data-v-36c2f7eb] {
  display: block;
  text-align: left;
  color: #666;
}
.listModule .list li[data-v-36c2f7eb] {
  cursor: pointer;
  padding: 20px 0;
  border-bottom: 1px solid #d3d3d3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 250px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.listModule .list li img[data-v-36c2f7eb] {
  width: 340px;
  height: 100%;
  margin-right: 20px;
  -o-object-fit: cover;
     object-fit: cover;
}
.listModule .text-info[data-v-36c2f7eb] {
    height: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    /* align-items: flex-start; */
}
.skeleton[data-v-36c2f7eb] {
  width: 1200px;
  margin: 0 auto;
}
.title-bg[data-v-36c2f7eb] {
    height: 130px;
    background: url(../img/title-bg.cd4e80a0.png) no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.newbg[data-v-36c2f7eb]{
    background: url(../img/class_newbg.ecc6569c.jpg) no-repeat;
    background-size: cover;
}
.title-inner[data-v-36c2f7eb] {
  width: 1200px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title-inner img[data-v-36c2f7eb] {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.title-inner h3[data-v-36c2f7eb] {
  font-size: 30px;
  margin-bottom: 0;
  background: -webkit-gradient(linear, left top, right top, from(#DCFBFE), to(#6EA2DF));
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  -webkit-background-clip: text;
  color: transparent;
}
.news-tab-wrap[data-v-36c2f7eb] {
  height: 60px;
  -webkit-box-shadow: 0 3px 5px #ccc;
          box-shadow: 0 3px 5px #ccc;
}
.news-tab[data-v-36c2f7eb] {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.news-tab li[data-v-36c2f7eb] {
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
  cursor: pointer;
  text-align: left;
  border-bottom: 2px solid #fff;
}
.no[data-v-36c2f7eb] {
  padding-top: 120px;
}
.no img[data-v-36c2f7eb] {
  width: 100px;
  height: 100px;
}
.no p[data-v-36c2f7eb] {
  color: #bbb;
  text-align: center;
}
