
.listModule[data-v-b808f49e] {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
.listModule .list[data-v-b808f49e] {
  width: 1200px;
  margin: 0 auto 20px;
}
.listModule .title[data-v-b808f49e] {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  text-align: left;
}
.listModule .describe[data-v-b808f49e] {
  text-align: left;
  color: #666;
}
.listModule .date[data-v-b808f49e] {
  display: block;
  text-align: left;
  color: #666;
}
.listModule .list li[data-v-b808f49e] {
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid #eee;
  border-radius: 4px;
  height: 165px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.listModule .list li[data-v-b808f49e]:hover {
  background: #f6f6f6;
}
.listModule .list li img[data-v-b808f49e] {
  width: 220px;
  margin-right: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
}
.skeleton[data-v-b808f49e] {
  width: 1200px;
  margin: 0 auto;
}
.no[data-v-b808f49e] {
  padding-top: 120px;
}
.no img[data-v-b808f49e] {
  width: 100px;
  height: 100px;
}
.no p[data-v-b808f49e] {
  color: #bbb;
}
@media screen and (max-width: 1024px) {
.listModule .list[data-v-b808f49e] {
    width: 100%;
}
.listModule .describe[data-v-b808f49e] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.listModule[data-v-b808f49e]{
    padding-top: 0;
}
}
