
.notice[data-v-0b7f252a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 20px;
}
.notice .more[data-v-0b7f252a] {
  cursor: pointer;
}
.notice .titleWrap[data-v-0b7f252a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 40px;
    margin-bottom: 10px;
}
.notice .title[data-v-0b7f252a] {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
}
.notice .title[data-v-0b7f252a]::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: #11A0F8;
}
.notice-content[data-v-0b7f252a] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 256px;
    border: 1px solid #E9E9E9;
    border-radius: 6px;
    padding: 30px 20px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: #fff;
}
.notice-content[data-v-0b7f252a]::-webkit-scrollbar, .player-list[data-v-0b7f252a]::-webkit-scrollbar {
    width:3px;
}
.notice-content[data-v-0b7f252a]::-webkit-scrollbar-thumb, .player-list[data-v-0b7f252a]::-webkit-scrollbar-thumb{
      background-color:#aaa;
}
.notice .articleT[data-v-0b7f252a] {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
    line-height: 20px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 280px;
}
.notice-content li[data-v-0b7f252a] {
    cursor: pointer;
    margin-bottom: 25px;
}
.notice-content li[data-v-0b7f252a]:last-child {
    margin-bottom: 0;
}
.notice-content span[data-v-0b7f252a] {
    display: block;
    text-align: left;
    line-height: 12px;
    color: #666;
}
.notice-item-title[data-v-0b7f252a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 15px;
}
.notice-item-title .icon[data-v-0b7f252a] {
    margin-right: 10px;
    background: #FF8684;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    height: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
@media screen and (max-width: 1024px) {
.notice[data-v-0b7f252a] {
      width: 100%!important;
}
}
