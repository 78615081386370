
.have_class_hours[data-v-87ad7518] {
    padding: 20px;
    min-height: 500px;
}
.title-wrap[data-v-87ad7518] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    padding-bottom: 20px;
}
.title[data-v-87ad7518] {
    text-align: left;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 0;
}
.title-left p[data-v-87ad7518] {
    margin-bottom: 0;
    text-align: left;
}
.select-title-wrap[data-v-87ad7518] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}
.loading[data-v-87ad7518] {
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.empty[data-v-87ad7518] {
    min-height: 500px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #888;
}
.empty img[data-v-87ad7518] {
    width: 121px;
    height: 74px;
}
